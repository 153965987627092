import React, { Component } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

import ReactInputMask from "react-input-mask";

import "./VerifyCard.css";

class VerifyCard extends Component {
  render() {
    return (
      <div className="verify_idcard">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Form className="verifyid_form">
              <h3 className="verify_subheading">Verify ID Information</h3>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Company Name
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    className="addproduct_form"
                    type="text"
                    name="businessAddress"
                    value={this.props.cardVerify.businessAddress}
                    onChange={this.props.handleIdData}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Business Address
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    className="addproduct_form"
                    type="text"
                    name="companyAddress"
                    value={this.props.cardVerify.companyAddress}
                    onChange={this.props.handleIdData}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  First and Last Name
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="text"
                    className="addproduct_form"
                    name="fullName"
                    value={this.props.cardVerify.fullName}
                    onChange={this.props.handleIdData}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  CNIC Number
                </Form.Label>
                <Col column sm={12} md={12} lg={9}>
                  {/* <Form.Control
                    type="number"
                    className="addproduct_form"
                    name="cnicNumber"
                    value={this.props.cardVerify.cnicNumber}
                    onChange={this.props.handleIdData}
                  /> */}

                  <ReactInputMask
                    required
                    mask={"99999-9999-999-9"}
                    alwaysShowMask={false}
                    type="text"
                    className="addproduct_form"
                    name="cnicNumber"
                    value={this.props.cardVerify.cnicNumber}
                    onChange={this.props.handleIdData}
                  >
                    {() => (
                      <Form.Control
                        className="addproduct_form"
                        name="cnicNumber"
                        value={this.props.cardVerify.cnicNumber}
                      />
                    )}
                  </ReactInputMask>
                </Col>
              </Form.Group>

              {this.props.frontId ? (
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2} />

                  <Col sm={12} md={12} lg={9}>
                    <div
                      className="position-relative uploaded-img-div"
                      style={{
                        maxHeight: "125px",
                        maxWidth: "125px",
                      }}
                    >
                      <span className="position-absolute img-remove-btn">
                        <CloseIcon
                          style={{ fontSize: "13px" }}
                          onClick={this.props.removeImage}
                        />
                      </span>
                      <img
                        src={this.props.frontId}
                        alt="id-card-front-img"
                        className="uploaded-img"
                      />
                    </div>
                  </Col>
                </Form.Group>
              ) : (
                ""
              )}

              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Upload ID - Front Side
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Label
                    for="exampleCustomFileBrowser"
                    className="uploadButton form-control"
                  >
                    {this.props.isUpload ? (
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <span className="mr-3">Uploading Image...</span>
                        <CircularProgress color="primary" size={20} />
                      </div>
                    ) : (
                      "Click here to upload image"
                    )}
                    <Form.Control
                      type="file"
                      name="frontId"
                      id="exampleCustomFileBrowser"
                      label="Upload Images"
                      onChange={this.props.handleFrontId}
                      hidden
                      disabled={this.props.isUpload ? true : false}
                    />
                  </Form.Label>
                </Col>
              </Form.Group>

              {this.props.backId ? (
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2} />
                  <Col sm={12} md={12} lg={9}>
                    <div
                      className="position-relative uploaded-img-div"
                      style={{
                        maxHeight: "125px",
                        maxWidth: "125px",
                      }}
                    >
                      <span className="position-absolute img-remove-btn">
                        <CloseIcon
                          style={{ fontSize: "13px" }}
                          onClick={this.props.removeBackImage}
                        />
                      </span>
                      <img
                        src={this.props.backId}
                        alt="Id-card-back-img"
                        className="uploaded-img"
                      />
                    </div>
                  </Col>
                </Form.Group>
              ) : (
                ""
              )}

              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Upload ID - Back Side
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Label
                    for="exampleCustomFileBrowsers"
                    className="uploadButton form-control"
                  >
                    {this.props.isUploadBack ? (
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <span className="mr-3">Uploading Image...</span>
                        <CircularProgress color="primary" size={20} />
                      </div>
                    ) : (
                      "Click here to upload image"
                    )}
                    <Form.Control
                      type="file"
                      name="backId"
                      id="exampleCustomFileBrowsers"
                      label="Upload Image"
                      onChange={this.props.handleBackId}
                      hidden
                      disabled={this.props.isUploadBack ? true : false}
                    />
                  </Form.Label>
                </Col>
              </Form.Group>
            </Form>
          </Grid>
        </Grid>
        <div className="float-right add-update-btn-m common-buttons">
          <Button
            className="d-flex align-items-center"
            onClick={this.props.handleVerifyId}
            disabled={this.props.isLoading ? true : false}
          >
            {this.props.isLoading ? (
              <>
                <span className="mr-3">Please wait</span>
                <CircularProgress color="inherit" size={20} />
              </>
            ) : this.props.isUpdate ? (
              "Update Details"
            ) : (
              "Add Details"
            )}
          </Button>
        </div>
      </div>
    );
  }
}
export default VerifyCard;
