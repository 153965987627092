import React from "react";
import { FaCartPlus } from "react-icons/fa";
// import { FaUsers } from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import { RiArrowDownSFill } from "react-icons/ri";
import { RiArrowUpSFill } from "react-icons/ri";
// import { CgReorder } from "react-icons/cg";
import { GiReturnArrow } from "react-icons/gi";
import { FaFileInvoice } from "react-icons/fa";
import { RiShoppingCartFill } from "react-icons/ri";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <RiDashboardFill />,
  },

  {
    title: "Products",
    icon: <FaCartPlus />,
    iconClosed: <RiArrowDownSFill />,
    iconOpened: <RiArrowUpSFill />,
    subNav: [
      {
        title: "View",
        path: "/products/view-products",
      },
      {
        title: "Add",
        path: "/products/add-product/new",
      },
    ],
  },

  {
    title: "Invoices",
    path: "/invoices",
    icon: <FaFileInvoice />,
  },
  {
    title: "Orders",
    path: "/orders/view-orders",
    icon: <RiShoppingCartFill />,
  },
  {
    title: "Return Requests",
    path: "/viewreturnrequests",
    icon: <GiReturnArrow />,
  },

  // {
  //   title: "Customers",
  //   icon: <FaUsers />,
  //   iconClosed: <RiArrowDownSFill />,
  //   iconOpened: <RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: "View",
  //       path: "/customers/view-customers",
  //     },
  //     // {
  //     //   title: "Add",
  //     //   path: "/customers/add-customer/new",
  //     // },
  //   ],
  // },
];
