import React, { useState, useEffect } from "react";

import { Button, Card } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams, Link } from "react-router-dom";
import Box from "@material-ui/core/Box";

import Sidebar from "../../components/sidebar/Sidebar";
import { axiosClient } from "../../helper";
import "./invoice.css";

const PrintInvoice = (props) => {
  const params = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [orderId, setOrderId] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const id = params.id;
    axiosClient()
      .get(`/getInvoiceByInvoiceId/${id}`)
      .then((res) => {
        setIsLoading(false);
        setInvoiceData(res.data.data);
        setOrderId(res.data.data.orderId);
        let prods = res.data.data.orderId.orderItems.filter(
          (p) => p.sellerId._id === localStorage.getItem("sellerId")
        );
        setFilteredProducts(prods);
      })
      .catch((error) => {
        setIsLoading(false);
        // console.log(error);
      });
  }, [params.id]);

  return (
    <>
      <Sidebar />
      <div className="addproduct_div">
        <Grid container>
          <Grid item lg={12}>
            {isLoading ? (
              <div className="loaders">
                <CircularProgress />
              </div>
            ) : (
              <div className="invoice-details">
                <Card variant="outlined">
                  <CardContent style={{ paddingTop: "24px" }}>
                    <div className="invoice-viewer py-16 pl-3px">
                      <div className="viewer_actions px-3 mb-3 d-flex align-items-center justify-content-between">
                        <Link to="/invoices" style={{ marginTop: "-8px" }}>
                          <i className="i-Left1 text-20 font-weight-700">
                            <KeyboardBackspaceIcon />{" "}
                          </i>
                        </Link>
                        <div>
                          <Button
                            onClick={() => window.print()}
                            variant="warning"
                            style={{ fontSize: "12px" }}
                          >
                            Print Invoice
                          </Button>
                        </div>
                      </div>

                      <div id="print-area" className="px-3">
                        <div className="row">
                          <div className="col-md-6">
                            <h5 style={{ fontWeight: "bold" }}>Order Info</h5>
                            {orderId?.refId}
                          </div>
                          <div className="col-md-6 text-sm-right">
                            <p className="text-capitalize">
                              <strong>Order status: </strong>
                              {invoiceData.status}
                            </p>
                            <p>
                              <strong>Order date: </strong>
                              <span>
                                {new Date(
                                  orderId.createdAt
                                ).toLocaleDateString()}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="mt-3 mb-4 border-top"></div>
                        <div className="mb-4">
                          <div className="d-flex justify-content-between">
                            <Box className="seller-buyer-details">
                              <div className="col-sm-12 text-sm-left">
                                <h6
                                  className="font-weight-bold"
                                  style={{
                                    paddingTop: "20px",
                                  }}
                                >
                                  Customer Details:
                                </h6>

                                {orderId.customerName}
                                <p className="mb-0">
                                  {orderId.shippingAddress}
                                </p>
                                <p className="mb-0">{orderId.cityName}</p>
                                <p className="mb-0">{orderId.phone}</p>
                              </div>
                            </Box>
                            <Box className="seller-buyer-details">
                              <div className="col-sm-12 text-sm-left">
                                <h6
                                  className="font-weight-bold"
                                  style={{
                                    paddingTop: "20px",
                                  }}
                                >
                                  Seller Details:
                                </h6>
                                <p className="mb-0">
                                  {filteredProducts[0]?.sellerId.fullName}
                                </p>
                                <p className="mb-0">
                                  {filteredProducts[0]?.sellerId.shopName}
                                </p>
                                <p className="mb-0">
                                  {filteredProducts[0]?.sellerId.address}
                                  {/* {
                                    filteredProducts[0]?.productId.userID
                                      .shopName
                                  } */}
                                </p>
                                <p className="mb-0">
                                  {filteredProducts[0]?.sellerId.city}
                                </p>
                              </div>
                            </Box>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 table-responsive">
                            <table className="table mb-4 invoice-details-table">
                              <thead>
                                <tr>
                                  <th scope="col" style={{ maxWidth: "100px" }}>
                                    Item Description{" "}
                                  </th>
                                  <th scope="col">Item SKU</th>
                                  <th scope="col">Qty</th>

                                  <th scope="col">Unit Price</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Tax</th>
                                  <th scope="col">Shipping charges</th>

                                  <th scope="col">Total cost</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredProducts?.map((data) => (
                                  <tr>
                                    <td style={{ maxWidth: "100px" }}>
                                      {data.productName}
                                    </td>
                                    <td>{data.productId.SKU} </td>
                                    <td>{data.quantity}</td>
                                    <td>{data.unitPrice}&nbsp;rs/-</td>
                                    <td>
                                      {data.unitPrice * data.quantity}&nbsp;rs/-
                                    </td>
                                    <td>{data.tax}&nbsp;rs/-</td>
                                    <td>
                                      {data.productShippingCharges}&nbsp;rs/-
                                    </td>
                                    <td>
                                      {data.unitPrice * data.quantity +
                                        data.productShippingCharges +
                                        data.tax}
                                      &nbsp;rs/-
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          <div className="col-md-12 col-sm-12">
                            <div className="d-flex justify-content-end">
                              <h6 className="font-weight-bold">
                                Products Total:
                              </h6>
                              <span className="total-charges">
                                {filteredProducts?.reduce(
                                  (a, v) => (a = a + v.quantity * v.unitPrice),
                                  0
                                )}
                                &nbsp;rs/-
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12">
                            <div className="d-flex justify-content-end">
                              <h6 className="font-weight-bold">
                                Shipping Charges:
                              </h6>
                              <span className="total-charges">
                                {filteredProducts?.reduce(
                                  (a, v) => (a = a + v.productShippingCharges),
                                  0
                                )}
                                &nbsp;rs/-
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12">
                            <div className="d-flex justify-content-end">
                              <h6 className="font-weight-bold">Total Tax:</h6>
                              <span className="total-charges">
                                {filteredProducts?.reduce(
                                  (a, v) => (a = a + v.tax),
                                  0
                                )}
                                &nbsp;rs/-
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12">
                            <div className="d-flex justify-content-end">
                              <h6 className="font-weight-bold">Total:</h6>
                              <span className="total-charges">
                                {filteredProducts?.reduce(
                                  (a, v) =>
                                    (a =
                                      a +
                                      v.productShippingCharges +
                                      v.tax +
                                      v.unitPrice * v.quantity),
                                  0
                                )}
                                &nbsp;rs/-
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default PrintInvoice;
