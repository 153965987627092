import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import Sidebar from "../../components/sidebar/Sidebar";
import { axiosClient } from "../../helper";

export default class ReturnRequests extends Component {
  state = {
    returnRequests: [],
  };
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableHeadCell: {
          data: {
            whiteSpace: "pre",
          },
        },
      },
    });
  componentDidMount() {
    let id = localStorage.getItem("sellerId");
    this.getReturnRequests(id);
  }

  getReturnRequests = (id) => {
    axiosClient()
      .get(`/getReturnRequestsBySellerId/${id}`)
      .then((response) => {
        this.setState({ returnRequests: response.data.returnRequests });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getBuyer = (value, tableMeta) => {
    var str = "";
    str = str + value.name;
    return str;
  };

  getProducts = (value, tableMeta) => {
    var str = "";
    value.forEach((element) => {
      str = str + element.productName + "</br>";
    });
    return str;
  };

  updateRequestStatus = (event, value) => {
    axiosClient()
      .post(`/changeStatusOfReturnOrderRequestById/${value.rowData[1]}`, {
        status: event.target.value,
      })
      .then((response) => {
        toast.success("Return status updated");
      })
      .catch((error) => {
        toast.error(error?.response?.data.message);
      });
  };

  render() {
    const options = {
      filterType: "dropdown",
      responsive: "standard",
      filter: true,
      expandableRows: true,
      expandableRowsHeader: true,
      expandableRowsOnClick: true,
      selectableRows: false,

      isRowExpandable: (dataIndex, expandedRows) => {
        return true;
      },
      renderExpandableRow: (rowData, rowMeta) => {
        const product = this.state.returnRequests[rowMeta.dataIndex];
        return (
          <TableRow>
            <TableCell colSpan={12}>
              <Table size="small" className="collapse-rows">
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <img
                        src={product.productId.image[0]}
                        alt="product img"
                        style={{
                          maxWidth: "50px",
                          maxHeight: "50px",
                          margin: "auto",
                        }}
                      />
                    </TableCell>
                    <TableCell>{product.productName}</TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>{product.price}</TableCell>
                    {/* <TableCell>{product.status}</TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        );
      },
      onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
        // console.log(curExpanded, allExpanded, rowsExpanded, "kyads"),
        console.log(""),
    };

    const columns = [
      {
        name: "refId",
        label: "Order ID",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value;
          },
        },
      },
      {
        name: "_id",
        label: "Reference #",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value;
          },
        },
      },
      {
        name: "reason",
        label: "Reason",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value;
          },
        },
      },
      {
        name: "orderId",
        label: "Customer Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value?.customerName;
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === "Withdrawal") {
              return <div className="withdrawal">{value}</div>;
            } else {
              return (
                <FormControl>
                  <Select
                    className={
                      value === "Awaiting"
                        ? "awaiting"
                        : value === "Approved"
                        ? "approved"
                        : value === "Rejected"
                        ? "rejected"
                        : ""
                    }
                    disableUnderline={true}
                    label={value}
                    value={value}
                    onChange={(event) => {
                      updateValue(event.target.value);
                      this.updateRequestStatus(event, tableMeta);
                    }}
                  >
                    <MenuItem value="Awaiting">Awaiting</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                  </Select>
                </FormControl>
              );
            }
          },
        },
      },
      {
        name: "orderId",
        label: "Order Date",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <div className="white-space-nowrap mb-1">
                  {new Date(value?.createdAt).toDateString()}
                </div>
                <div className="white-space-nowrap">
                  {new Date(value?.createdAt).toLocaleTimeString()}
                </div>
              </>
            );
          },
        },
      },

      {
        name: "orderId",
        label: "Order Date",
        options: {
          display: "excluded",
          customBodyRender: (value, tableMeta, updateValue) => {
            return value?.createdAt;
          },
        },
      },
    ];
    return (
      <>
        <ToastContainer autoClose={2000} />
        <Sidebar />
        <div className="view_orders_div view-muitables">
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title={"Return Requests"}
              data={this.state.returnRequests}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </div>
      </>
    );
  }
}
