import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import iownLogo from "../../assets/iownLogoSignin.png";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";

import { axiosClient } from "../../helper";
import classes from "./Login.module.css";

const regEmail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Login extends Component {
  state = {
    email: "",
    password: "",
    isLoading: false,
  };

  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleLogin = (e) => {
    e.preventDefault();
    const loginDetails = {
      email: this.state.email,
      password: this.state.password,
    };
    if (!this.state.email || !this.state.password) {
      toast.error("All fields are required");
    } else if (!regEmail.test(this.state.email)) {
      toast.error("Invalid email format");
    } else {
      this.setState({ isLoading: true });
      axiosClient()
        .post("/sellerLogin", loginDetails)
        .then((res) => {
          this.setState({ isLoading: false });
          if (
            res.status === 200 &&
            res.data.status === "success" &&
            res.data.user.role === "seller"
          ) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userRole", res.data.user.role);
            localStorage.setItem("sellerId", res.data.user._id);
            localStorage.setItem("sellerName", res.data.user.fullName);
            let d = new Date();
            d = d.getTime();
            let date = d + 170000000;
            localStorage.setItem("tokenExpiry", date);
            localStorage.setItem("isSellerVerified", res.data.user.verify);

            // res.data.user.verify
            //   ? this.props.history.push(`/dashboard`)
            //   : this.props.history.push(`/new-dashboard`);
            res.data.user.verify
              ? (window.location = `/dashboard`)
              : (window.location = `/new-dashboard`);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error(error.response?.data.message);
        });
    }
  };
  render() {
    return (
      <>
        <SimpleHeader />
        {this.state.isLoading ? (
          <div className={classes.post_loader}>
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        <ToastContainer />
        <Container className={this.state.isLoading ? classes.load_opacity : ""}>
          <Row className="justify-content-center">
            <Col sm={10} md={8} lg={5}>
              <div className={classes.login_div}>
                <img
                  src={iownLogo}
                  alt="iown.pk"
                  className={classes.iownLogo}
                />
                {/* <h4 className={classes.logo}>Deosai</h4> */}
                <Card className={classes.login_card}>
                  <Card.Title className={classes.login_heading}>
                    Sign In
                  </Card.Title>
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        className={classes.login_form_control}
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        className={classes.login_form_control}
                        value={this.state.password}
                        onChange={this.onChangePassword}
                      />
                    </Form.Group>
                  </Form>

                  <Button
                    onClick={this.handleLogin}
                    style={{ backgroundColor: "#2d88ff" }}
                    className={classes.loginbtn}
                    type="submit"
                  >
                    Sign in
                  </Button>

                  <div className={classes.forgot_div}>
                    <span className={classes.get_started}>
                      New to Deosai? <Link to="/signup">Get Started</Link>
                    </span>
                    <span>
                      <Link to="/email-verify" className={classes.forgot}>
                        Forgot Password ?
                      </Link>
                    </span>
                  </div>
                </Card>
                <div className={classes.footer}>
                  <Link to="/help">Help</Link>
                  <Link to="/privacy">Privacy</Link>
                  <Link to="/terms">Terms</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default Login;
