import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./Signup.css";
import BasicInfoForm from "../../components/signup/basicInfoForm/BasicInfoForm";
// import PassCode from "../../components/signup/passCode/Passcode";
import CompleteProfile from "../../components/signup/completeProfile/CompleteProfile";
import axiosClient from "../../helper";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Basic Info Form", "Account"];
}

function Signup() {
  const history = useHistory();
  const classes = useStyles();
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [accountType, setAccountType] = useState("Individual");
  const [shopLocation, setShopLocation] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  // const [passcode, setPasscode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [shopName, setShopName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [shopNameErr, setShopNameErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [isAcceptedErr, setIsAcceptedErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeErr, setPostalCodeErr] = useState("");
  const [shopLocationErr, setShopLocationErr] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [addressErr, setAddressErr] = useState("");

  const errors = [];
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const formData = {
    accountType: accountType,
    provinceId: shopLocation,
    postalCode: postalCode,
    city: selectedCity,
    phone: phoneNo,
    password: password,
    email: email,
    fullName: name,
    shopName: shopName,
    address: businessAddress,
  };

  useEffect(() => {
    getAllProvinces();
  }, []);

  const getAllProvinces = () => {
    axiosClient()
      .get(`/getAllProvince`)
      .then((response) => {
        setProvinces(response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getCitiesByProvinceId = (id) => {
    axiosClient()
      .get(`/getCitiesByProvinceId/${id}`)
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleNext = () => {
    handleValidate();
    if (errors.length > 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onChangeAccountType = (e) => {
    setAccountType(e.target.value);
  };

  const onChangeShopLocation = (e) => {
    setShopLocation(e.target.value);
    getCitiesByProvinceId(e.target.value);
    if (e.target.value) {
      setShopLocationErr(null);
    }
  };

  const onChangeCity = (e) => {
    setSelectedCity(e.target.value);
    if (e.target.value) {
      setCityErr(null);
    }
  };

  const onChangeBusinessAddress = (e) => {
    setBusinessAddress(e.target.value);
    if (e.target.value) {
      setAddressErr(null);
    }
  };

  const onChangePostalCode = (e) => {
    setPostalCode(e.target.value);
    if (e.target.value) {
      setPostalCodeErr(null);
    }
  };
  const onChangePhoneNo = (e) => {
    setPhoneNo(e.target.value);
    if (e.target.value && e.target.value.replace(/-|_/g, "").length >= 15) {
      setPhoneErr(null);
    }
  };

  const onChangeIsAccepted = () => {
    setIsAccepted(!isAccepted);
    if (isAccepted) {
      setIsAcceptedErr(null);
    }
  };

  // const onChangePasscode = (e) => {
  //   setPasscode(e.target.value);
  // };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    if (e.target.value) {
      setPasswordErr(null);
    }
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value && e.target.value === password) {
      setConfirmPasswordErr(null);
    }
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value || regEmail.test(email)) {
      setEmailErr(null);
    }
  };

  const onChangeName = (e) => {
    setName(e.target.value);
    if (e.target.value) {
      setNameErr(null);
    }
  };

  const onChangeShopName = (e) => {
    setShopName(e.target.value);
    if (e.target.value) {
      setShopNameErr(null);
    }
  };

  const handleValidate = () => {
    if (activeStep === 0) {
      if (formData.accountType === "") errors.push("Account Type");
      if (formData.shopLocation === "") errors.push("Shop Location");
      if (!formData.phone || formData.phone.replace(/-|_/g, "").length < 15) {
        errors.push(phoneErr);
      }

      if (!phoneNo) {
        setPhoneErr("Phone # is required");
      } else if (phoneNo.replace(/-|_/g, "").length < 15) {
        setPhoneErr("Phone number is invalid");
      }

      if (!formData.city) errors.push(cityErr);
      if (!selectedCity) {
        setCityErr("City is required");
      }

      if (!formData.postalCode) errors.push(postalCodeErr);
      if (!postalCode) {
        setPostalCodeErr("Postal Code is required");
      }

      if (!formData.provinceId) errors.push(shopLocationErr);
      if (!shopLocation) {
        setShopLocationErr("Location is required");
      }

      if (!formData.address) errors.push(addressErr);
      if (!businessAddress) {
        setAddressErr("Business address is required");
      }

      if (!isAccepted) {
        errors.push(isAcceptedErr);
      }
      if (!isAccepted) {
        setIsAcceptedErr(
          "You must agree to deosai's terms & conditions to signup"
        );
        toast.error("You must agree to deosai's terms & conditions to signup");
      }

      if (errors.length > 0) return errors.length;
    } else if (activeStep === 1) {
      if (formData.password === "") {
        errors.push(passwordErr);
      }
      if (password === "") {
        setPasswordErr("Password is required");
      }
      if (confirmPassword === "" || confirmPassword !== password) {
        errors.push(confirmPasswordErr);
      }
      if (confirmPassword === "") {
        setConfirmPasswordErr("Confirm Password is required");
      } else if (confirmPassword !== password) {
        setConfirmPasswordErr("Password & confirm password does not match");
      }
      if (formData.name === "") errors.push(nameErr);
      if (name === "") {
        setNameErr("Name is required");
      }
      if (formData.email === "" || !regEmail.test(email)) errors.push(emailErr);
      if (email === "") {
        setEmailErr("Email is required");
      } else if (!regEmail.test(email)) {
        setEmailErr("email's pattern must be example@email.com");
      }
      if (formData.shopName === "") errors.push(shopNameErr);
      if (shopName === "") {
        setShopNameErr("Shop Name is required");
      }
    }
    if (errors.length > 0) {
      return errors.length;
    }
    return errors.length;
  };

  const handleSubmit = () => {
    if (handleValidate() > 0) {
      toast.error("Please fill all fields properly");
      return;
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/signupSeller", formData)
        .then((response) => {
          setIsLoading(false);
          toast.success(response?.data?.message);
          setTimeout(() => history.push("/"), 3000);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <BasicInfoForm
            accountType={accountType}
            phoneNo={phoneNo}
            shopLocation={shopLocation}
            isAccepted={isAccepted}
            onChangePhoneNo={onChangePhoneNo}
            onChangeAccountType={onChangeAccountType}
            onChangeShopLocation={onChangeShopLocation}
            provinces={provinces}
            cities={cities}
            selectedCity={selectedCity}
            onChangeCity={onChangeCity}
            postalCode={postalCode}
            onChangePostalCode={onChangePostalCode}
            onChangeIsAccepted={onChangeIsAccepted}
            phoneErr={phoneErr}
            isAcceptedErr={isAcceptedErr}
            cityErr={cityErr}
            postalCodeErr={postalCodeErr}
            shopLocationErr={shopLocationErr}
            buinessAddress={businessAddress}
            addressErr={addressErr}
            onChangeBusinessAddress={onChangeBusinessAddress}
          />
        );
      // case 1:
      //   return (
      //     <PassCode passcode={passcode} onChangePasscode={onChangePasscode} />
      //   );
      case 1:
        return (
          <CompleteProfile
            password={password}
            confirmPassword={confirmPassword}
            email={email}
            name={name}
            shopName={shopName}
            onChangePassword={onChangePassword}
            onChangeConfirmPassword={onChangeConfirmPassword}
            onChangeEmail={onChangeEmail}
            onChangeName={onChangeName}
            onChangeShopName={onChangeShopName}
            nameErr={nameErr}
            shopNameErr={shopNameErr}
            emailErr={emailErr}
            passwordErr={passwordErr}
            confirmPasswordErr={confirmPasswordErr}
          />
        );
      default:
      // return "Unknown stepIndex";
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className="post_loader">
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      <ToastContainer autoClose={3000} />
      <Container className={isLoading ? "load_opacity" : ""}>
        <Row style={{ margin: "6% auto 2%", justifyContent: "center" }}>
          <Col xs={12} sm={10} md={10} lg={6}>
            <div className="signup_root">
              <Stepper activeStep={activeStep} className="d-none">
                {steps.map((label) => (
                  <Step key={label}>
                    {/* <StepLabel>{label}</StepLabel> */}
                  </Step>
                ))}
              </Stepper>
              {/* <div> */}
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div className="buttons">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    activeStep === steps.length - 1 ? handleSubmit : handleNext
                  }
                >
                  {activeStep === steps.length - 1 ? "Signup" : "Next"}
                </Button>
              </div>
              {/* </div> */}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="already-seller">
        Already a seller? <Link to="/">login</Link>
      </div>
    </div>
  );
}

export default Signup;
