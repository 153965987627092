import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
// import SideBar from "./components/sidebar/Sidebar";
import Dashboard from "./pages/dashboard/Dashboard";
import AddressBook from "./pages/addressBook/AddressBook";
import VerifyIdCard from "./pages/verifyIdCard/VerifyIdCard";
import VerifyBankAccount from "./pages/verifyBankAccount/VerifyBankAccount";
import SeniorPersonInfo from "./pages/seniorPersonInfo/SeniorPersonInfo";
import OrderProcessInfo from "./pages/orderProcessInfo/OrderProcessInfo";
import ViewProducts from "./pages/viewProducts/ViewProducts";
import AddProduct from "./pages/addProduct/AddProduct";
import ViewOrders from "./pages/viewOrders/ViewOrders";
// import AddOrder from "./pages/addOrder/AddOrder";
// import ViewCustomers from "./pages/viewCustomers/ViewCustomers";
// import AddCustomer from "./pages/addCustomer/AddCustomer";
import ProtectedRoute from "./components/protectedRoute";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy/Privacy";
import Help from "./pages/Help/Help";
import ReturnRequests from "./pages/returnRequests/ViewReturnRequests";
import EmailVerify from "./pages/foregetPassword/emailVerify";
import VerifyCode from "./pages/foregetPassword/verifyCode";
import ResetPassword from "./pages/foregetPassword/resetPassword";
import DashboardFront from "./pages/newDashboard/dashboardFront";
import Invoices from "./pages/viewAllInvoices/allInvoices";
import PrintInvoice from "./pages/viewAllInvoices/printInvoice";

function App() {
  useEffect(() => {
    let expiry = localStorage.getItem("tokenExpiry");
    let currentDate = new Date();
    if (currentDate.getTime() > expiry) {
      localStorage.clear();
    }
  }, []);
  let newDashboardRoutes = (
    <Switch>
      <ProtectedRoute path="/new-dashboard" component={DashboardFront} />
      {localStorage.getItem("token") &&
      localStorage.getItem("isSellerVerified") === "false" ? (
        <Redirect to="/new-dashboard" />
      ) : null}
      <Route exact path="/" component={Login} />
      <Redirect to="/" />
    </Switch>
  );
  let unauthRoutes = (
    <Switch>
      <ProtectedRoute path="/new-dashboard" component={DashboardFront} />
      <ProtectedRoute path="/dashboard" component={Dashboard} />
      <Route path="/terms" component={Terms} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/help" component={Help} />
      <Route path="/signup" component={Signup} />
      <Route path="/reset-password/:code" component={ResetPassword} />
      <Route path="/verify-code" component={VerifyCode} />
      <Route path="/email-verify" component={EmailVerify} />
      <Route exact path="/" component={Login} />
      <Redirect to="/" />
    </Switch>
  );

  let route = (
    <div>
      <Switch>
        <Route path="/reset-password/:code" component={ResetPassword} />
        <Route path="/verify-code" component={VerifyCode} />
        <Route path="/email-verify" component={EmailVerify} />

        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <ProtectedRoute path="/address" component={AddressBook} />
        <ProtectedRoute path="/verify-id" component={VerifyIdCard} />
        <ProtectedRoute
          path="/verify-bankaccount"
          component={VerifyBankAccount}
        />
        <ProtectedRoute path="/contact-details" component={SeniorPersonInfo} />
        <ProtectedRoute
          path="/orderprocess-info"
          component={OrderProcessInfo}
        />
        <ProtectedRoute
          path="/products/view-products"
          component={ViewProducts}
        />
        <ProtectedRoute
          path="/products/add-product/new"
          component={AddProduct}
        />
        <ProtectedRoute path="/orders/view-orders" component={ViewOrders} />

        <ProtectedRoute path="/viewreturnrequests" component={ReturnRequests} />
        <ProtectedRoute path="/invoices" component={Invoices} />
        <ProtectedRoute
          exact
          path="/print-invoice/:id"
          component={PrintInvoice}
        />
        {localStorage.getItem("token") &&
        localStorage.getItem("isSellerVerified") === "true" ? (
          <Redirect to="/dashboard" />
        ) : (
          <Redirect to="/" />
        )}
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/help" component={Help} />
        <Route path="/signup" component={Signup} />
        <Route exact path="/" component={Login} />
      </Switch>
    </div>
  );
  return localStorage.getItem("isSellerVerified") === "true" ? (
    <Router>
      <ToastContainer autoClose={3000} />
      {route}
    </Router>
  ) : localStorage.getItem("isSellerVerified") === "false" ? (
    <Router>
      <ToastContainer autoClose={3000} />
      {newDashboardRoutes}
    </Router>
  ) : (
    <Router>
      <ToastContainer autoClose={3000} />
      {unauthRoutes}
    </Router>
  );
}

export default App;
