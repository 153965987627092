import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import SideBar from "../../components/sidebar/Sidebar";
import ContectDetail from "../../components/verification/contectDetail";
import axiosClient from "../../helper";

import "../verifyBankAccount/VerifyBankAccount.css";

const SeniorPersonInfo = () => {
  const [detailContact, setDetailContact] = useState({
    primaryName: "",
    primaryPhoneNo: "",
    primaryAddress: "",
    secondaryName: "",
    secondaryPhoneNo: "",
    secondaryAddress: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axiosClient()
      .get(`/getUsersById/${localStorage.getItem("sellerId")}`)
      .then((response) => {
        setDetailContact({
          ...detailContact,
          primaryName: response.data.user.primaryContactName,
          primaryPhoneNo: response.data.user.primaryContactPhone,
          primaryAddress: response.data.user.primaryContactAddress,
          secondaryName: response.data.user.secondaryContactName,
          secondaryPhoneNo: response.data.user.secondaryContactPhone,
          secondaryAddress: response.data.user.secondaryContactAddress,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleAddColleagueInfo = () => {
    let colleagueInfo = {
      primaryContactName: detailContact.primaryName,
      primaryContactPhone: detailContact.primaryPhoneNo,
      primaryContactAddress: detailContact.primaryAddress,
      secondaryContactName: detailContact.secondaryName,
      secondaryContactPhone: detailContact.secondaryPhoneNo,
      secondaryContactAddress: detailContact.secondaryAddress,
    };
    if (
      !detailContact.primaryName ||
      !detailContact.primaryPhoneNo ||
      !detailContact.primaryAddress ||
      !detailContact.secondaryName ||
      !detailContact.secondaryPhoneNo ||
      !detailContact.secondaryAddress
    ) {
      toast.error("All fields are required");
    } else if (detailContact.primaryPhoneNo.replace(/-|_/g, "").length < 15) {
      toast.error("Primary phone # is invalid");
    } else if (detailContact.secondaryPhoneNo.replace(/-|_/g, "").length < 15) {
      toast.error("Secondary phone # is invalid");
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/colleagueInformationBySeller", colleagueInfo)
        .then((response) => {
          toast.success("Details Updated");
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
          setIsLoading(false);
        });
    }
  };

  const handleContactData = (event) => {
    setDetailContact({
      ...detailContact,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <SideBar />
      <div className="m-div">
        <div className="verifybank_heading">Update Contact Details</div>
        <ContectDetail
          detailContact={detailContact}
          handleAddColleagueInfo={handleAddColleagueInfo}
          handleContactData={handleContactData}
          isUpdate={true}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
export default SeniorPersonInfo;
