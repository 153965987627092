import React, { Component } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./VerifyCard.css";

class VerifyAccount extends Component {
  render() {
    return (
      <div className="verify_idcard">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Form className="verifyid_form">
              <h3 className="verify_subheading">Payment Information</h3>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Account Title
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="text"
                    name="accountTitle"
                    value={this.props.accountVerify.accountTitle}
                    onChange={this.props.handleAccountData}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Account Number
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="number"
                    name="accountNumber"
                    value={this.props.accountVerify.accountNumber}
                    onChange={this.props.handleAccountData}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Bank Name
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    name="selectedBank"
                    value={this.props.accountVerify.selectedBank}
                    onChange={this.props.handleAccountData}
                  >
                    {this.props.accountVerify.bankName.map((bankname, i) => (
                      <option key={i} value={bankname}>
                        {bankname}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Branch Code
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="number"
                    name="branchCode"
                    value={this.props.accountVerify.branchCode}
                    onChange={this.props.handleAccountData}
                  />
                </Col>
              </Form.Group>
            </Form>
          </Grid>
        </Grid>
        <div className="float-right add-update-btn-m common-buttons">
          <Button
            className="d-flex align-items-center"
            onClick={this.props.handleAddBankDetails}
            disabled={this.props.isLoading ? true : false}
          >
            {this.props.isLoading ? (
              <>
                <span className="mr-3">Please wait</span>
                <CircularProgress color="inherit" size={20} />
              </>
            ) : this.props.isUpdate ? (
              "Update Details"
            ) : (
              "Add Details"
            )}
          </Button>
        </div>
        <div
          className={
            this.props.isBackBtn ? "float-right mr-1 common-buttons" : "d-none"
          }
        >
          <Button onClick={this.props.handleBack}>Back</Button>
        </div>
      </div>
    );
  }
}
export default VerifyAccount;
