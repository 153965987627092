import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "./emailVerify.module.css";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import axiosClient from "../../helper";

class VerifyCode extends Component {
  state = {
    code: "",
    isLoading: false,
  };

  onChangeEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePassword = (e) => {
    e.preventDefault();
    const loginDetails = {
      token: this.state.code,
    };
    if (!this.state.code) {
      toast.error("Please Enter Your Email Address ");
    } else {
      this.setState({ isLoading: true });
      axiosClient()
        .post(`/verifyToken`, loginDetails)
        .then((response) => {
          // console.log(response);
          this.setState({ isLoading: false });

          setTimeout(
            () => this.props.history.push(`/reset-password/${this.state.code}`),
            2000
          );
          toast.success(response.data.message);
        })
        .catch((error) => {
          // console.log(error);
          this.setState({ isLoading: false });

          toast.error(error.response.data.message);
        });
    }
  };
  render() {
    return (
      <>
        <SimpleHeader />

        <ToastContainer autoClose={2000} />
        <Container>
          <Row style={{ justifyContent: "center" }}>
            <Col sm={10} md={5}>
              <div className={classes.email_div}>
                <h4 className={classes.logo}>Verify your account</h4>

                <Card className={classes.forget_card}>
                  <Card.Title className={classes.forget_heading}>
                    Enter The verification code that we sent to your email in
                    order to activate your account
                  </Card.Title>
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        type="string"
                        placeholder="Enter Code"
                        name="code"
                        className={classes.forget_form_control}
                        value={this.state.code}
                        onChange={this.onChangeEmail}
                      />
                    </Form.Group>
                  </Form>

                  <Button
                    onClick={this.handlePassword}
                    className={classes.forgetbtn}
                    style={{ boxShadow: "none" }}
                    type="submit"
                    disabled={this.state.isLoading ? true : false}
                  >
                    {this.state.isLoading === true ? (
                      <span className="d-flex align-items-center justify-content-center">
                        <span className="mr-2">Please wait</span>
                        <CircularProgress
                          className="text-white"
                          color="inherit"
                          size={18}
                        />
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default VerifyCode;
