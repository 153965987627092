import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import "./ViewProducts.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { axiosClient } from "../../helper";
import { updateStatus } from "./store/actions";

class ViewProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      productStatus: [],
      status: "",
      id: "",
    };
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableHeadCell: {
          data: {
            whiteSpace: "pre",
          },
        },
      },
    });
  componentDidMount() {
    this.getProducts();
  }
  getProducts = () => {
    const id = localStorage.getItem("sellerId");
    axiosClient()
      .get(`/getProductsBySellerId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ products: response.data.products });
        }
      })
      .catch((error) => {
        // console.log("error in products:", error);
      });
  };
  userStatus = () => {
    this.setState({ status: !this.state.products.active });
  };
  toggleStatus = (event, value, rowKey) => {
    let statusVal = event.target.value;
    let status;
    if (statusVal === "Active") {
      status = true;
    } else {
      status = false;
    }
    updateStatus(value.rowData[3], status);
  };
  render() {
    const options = {
      filterType: "dropdown",
      responsive: "standard",
      selectableRows: false,
    };
    const columns = [
      {
        label: "Image",
        name: "image",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                {value.length !== 0 ? (
                  <img
                    src={value[0]}
                    alt="product"
                    style={{
                      maxWidth: "50px",
                      maxHeight: "50px",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <Avatar
                    variant="square"
                    style={{ width: "47px", height: "45px" }}
                  >
                    <LocalMallIcon />
                  </Avatar>
                )}
              </div>
            );
          },
        },
      },
      {
        name: "name",
        label: "Name",
        options: {
          setCellProps: () => ({ style: { maxWidth: "230px" } }),
        },
      },
      {
        name: "brand",
        label: "Brand",
      },
      {
        name: "_id",
        label: "ID",
        options: {
          filter: false,
          display: "excluded",
        },
      },
      {
        name: "price",
        label: "Price",
      },
      {
        name: "countInStock",
        label: "Stock",
        options: {
          customBodyRender: (value, tableMeta, updateValue, _id) => {
            return value ? value : "NA";
          },
        },
      },
      {
        label: "Status",
        name: "active",
        options: {
          customBodyRender: (value, tableMeta, updateValue, _id) => {
            return value === true ? "active" : "inactive";
          },
        },
      },
      {
        label: "Actions",
        name: "_id",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className="list-action">
                <VisibilityOutlinedIcon
                  style={{
                    color: "#666666",
                    marginRight: "4px",
                    cursor: "pointer",
                  }}
                  fontSize="small"
                  onClick={() =>
                    window.open(
                      `https://deosai.herokuapp.com/product-detail/${value}`
                    )
                  }
                />

                <CreateOutlinedIcon
                  style={{
                    color: "#666666",
                    marginTop: "-3px",
                    cursor: "pointer",
                  }}
                  fontSize="small"
                  onClick={() =>
                    this.props.history.push(`/products/add-product/${value}`)
                  }
                />
              </div>
            );
          },
        },
      },
    ];
    return (
      <>
        <Sidebar />
        <div className="view_products_div view-muitables">
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title={"Products"}
              data={this.state.products}
              columns={columns}
              options={options}
              rowKey={"_id"}
            />
          </MuiThemeProvider>
        </div>
      </>
    );
  }
}
export default ViewProducts;
