import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Paper from "@material-ui/core/Paper";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ToastContainer, toast } from "react-toastify";

import { axiosClient } from "../../helper";
import Sidebar from "../../components/sidebar/Sidebar";
import "./ViewOrders.css";

const ViewOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [val, setVal] = useState("");

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    const id = localStorage.getItem("sellerId");
    axiosClient()
      .get(`/getOrderBySellerId/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setOrders(res.data.orders);
        }
      });
  };

  const updateOrderStatus = (event, value, prodId, index) => {
    let id = prodId[index].productId._id;
    setVal("");
    axiosClient()
      .post(
        `/changeOrderStatusByOrderAndSellerIdAndProductId/${value}/${localStorage.getItem(
          "sellerId"
        )}/${id}`,
        { status: event.target.value }
      )
      .then((response) => {
        prodId[index].status = response.data.orderStatus.status;
        setVal("hello");
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data.message);
      });
  };

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {},
    selectableRows: false,
    filterType: "dropdown",
    rowsPerPage: 10,
    expandableRows: true,
    expandableRowsHeader: true,

    renderExpandableRow: (rowData, rowMeta, tableMeta, updateValue) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={8}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "1000" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell style={{ maxWidth: "200px" }}>
                        Product Name
                      </TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Commission</TableCell>
                      <TableCell>Delivery Charges</TableCell>
                      <TableCell>Tax</TableCell>
                      <TableCell>Total Cost</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders[rowMeta.dataIndex].orderId.orderItems.map(
                      (order, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <img
                              src={order.productId.image[0]}
                              alt="product"
                              style={{
                                maxWidth: "50px",
                                maxHeight: "50px",
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ maxWidth: "200px" }}>
                            <strong>{order.quantity}&nbsp;x</strong>
                            {` ${order.productName}`}
                          </TableCell>
                          <TableCell>{order.unitPrice}&nbsp;rs/-</TableCell>
                          <TableCell>
                            {order.adminCommissionAmount}&nbsp;rs/-
                          </TableCell>
                          <TableCell>
                            {order.productShippingCharges}&nbsp;rs/-
                          </TableCell>
                          <TableCell>{order.tax}&nbsp;rs/-</TableCell>
                          <TableCell>{order.price}&nbsp;rs/-</TableCell>
                          <TableCell>
                            {order.status === "Returned" ? (
                              <span>{order.status}</span>
                            ) : (
                              <FormControl className="change_status">
                                <Select
                                  className={
                                    order.status === "Awaiting"
                                      ? "awaiting"
                                      : order.status === "Shipped"
                                      ? "withdrawal"
                                      : order.status === "Delivered"
                                      ? "approved"
                                      : order.status === "Cancelled"
                                      ? "rejected"
                                      : ""
                                  }
                                  label={order.status}
                                  value={order.status}
                                  onChange={(event) => {
                                    updateOrderStatus(
                                      event,
                                      rowData[0],
                                      orders[rowMeta.dataIndex].orderId
                                        .orderItems,
                                      index
                                    );
                                  }}
                                >
                                  <MenuItem value="Awaiting">Awaiting</MenuItem>
                                  <MenuItem value="Shipped">Shipped</MenuItem>
                                  <MenuItem value="Delivered">
                                    Delivered
                                  </MenuItem>
                                  <MenuItem value="Accepted">Accepted</MenuItem>
                                  <MenuItem value="Order under processing">
                                    Order Under Processing
                                  </MenuItem>
                                  <MenuItem value="Cancelled">
                                    Cancelled
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
  };

  const columns = [
    {
      name: "orderId",
      label: "Order ID",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta) => {
          return value._id;
        },
      },
    },

    {
      name: "orderId",
      label: "Reference ID",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.refId;
        },
      },
    },
    {
      name: "orderId",
      label: "OrderItem Id",
      options: {
        filter: false,
        display: "excluded",
        customBodyRender: (value) => {
          return value.orderItems[0].productId._id;
        },
      },
    },
    {
      name: "orderId",
      label: "Shipping Address",
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <span>{value?.shippingAddress}</span>
              <br />
              <span>{value?.cityName}</span>
              <br />
              <span>{value?.country}</span>
              <br />
              <span>{value?.zipCode}</span>
            </>
          );
        },
      },
    },
    {
      name: "orderId",
      label: "Customer Name",
      options: {
        customBodyRender: (value) => {
          return value.customerName;
        },
      },
    },
    {
      name: "orderId",
      label: "Phone",
      options: {
        customBodyRender: (value) => {
          return value.phone;
        },
      },
    },
    {
      name: "orderId",
      label: "Total Price",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <span>{value?.totalPrice?.toString()}&nbsp;rs/-</span>;
        },
      },
    },

    {
      name: "orderId",
      label: "Order date",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="white-space-nowrap mb-1">
                {new Date(value?.createdAt).toDateString()}
              </div>
              <div className="white-space-nowrap">
                {new Date(value?.createdAt).toLocaleTimeString()}
              </div>
            </>
          );
        },
      },
    },

    {
      name: "orderId",
      label: "Order date",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.createdAt;
        },
      },
    },
  ];

  return (
    <>
      <ToastContainer autoClose={2000} />
      <Sidebar />
      <div className="view_orders_div view-muitables">
        <MUIDataTable
          title={"Orders"}
          data={orders}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
};

export default ViewOrders;
