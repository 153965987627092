import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import Sidebar from "../../components/sidebar/Sidebar";
import { axiosClient } from "../../helper";

class Invoices extends Component {
  state = {
    invoices: [],
  };
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableHeadCell: {
          data: {
            whiteSpace: "pre",
          },
        },
      },
    });
  componentDidMount() {
    let id = localStorage.getItem("sellerId");
    this.getInvoices(id);
  }

  getInvoices = (id) => {
    axiosClient()
      .get(`/getInvoicesBySellerId/${id}`)
      .then((response) => {
        this.setState({ invoices: response.data.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  render() {
    const options = {
      filterType: "dropdown",
      responsive: "standard",
      filter: true,
      selectableRows: false,
    };

    const columns = [
      {
        name: "orderId",
        label: "Order ID",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value?.refId;
          },
        },
      },

      {
        name: "orderId",
        label: "Customer Name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value?.customerName;
          },
        },
      },

      {
        name: "orderId",
        label: "Products",
        options: {
          filter: false,
          setCellProps: () => ({ style: { maxWidth: "230px" } }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return value?.orderItems.map((p) => (
              <div className="mb-1">
                <strong>{p.quantity} x</strong>
                {` ${p.productName}`}
              </div>
            ));
          },
        },
      },

      {
        name: "orderId",
        label: "Total Price",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return parseInt(value?.totalPrice);
          },
        },
      },
      {
        name: "orderId",
        label: "Phone",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value?.phone;
          },
        },
      },
      {
        name: "orderId",
        label: "Order Date",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <div className="white-space-nowrap mb-1">
                  {new Date(value?.createdAt).toDateString()}
                </div>
                <div className="white-space-nowrap">
                  {new Date(value?.createdAt).toLocaleTimeString()}
                </div>
              </>
            );
          },
        },
      },
      {
        label: "Actions",
        name: "_id",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className="list-action">
                <VisibilityOutlinedIcon
                  style={{
                    color: "#666666",
                    marginRight: "4px",
                    cursor: "pointer",
                  }}
                  fontSize="small"
                  onClick={() =>
                    this.props.history.push(`/print-invoice/${value}`)
                  }
                />
              </div>
            );
          },
        },
      },
      {
        name: "orderId",
        label: "Order Date",
        options: {
          display: "excluded",
          customBodyRender: (value, tableMeta, updateValue) => {
            return value?.createdAt;
          },
        },
      },
    ];
    return (
      <>
        <Sidebar />
        <div className="view_orders_div view-muitables">
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title={"Invoices"}
              data={this.state.invoices}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </div>
      </>
    );
  }
}
export default Invoices;
