import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "./emailVerify.module.css";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import axiosClient from "../../helper";

class ResetPassword extends Component {
  state = {
    password: "",
    confirmPassword: "",
    token: "",
    isLoading: false,
  };

  onChangeEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    let id = this.props.match.params.code;
    this.setState({ token: id });
  }
  handleForgetEmail = (e) => {
    e.preventDefault();
    const loginDetails = {
      newPassword: this.state.password,
      confirmPassword: this.state.confirmPassword,
      token: this.state.token,
    };
    if (!this.state.password || !this.state.confirmPassword) {
      toast.error("All Fields are required");
    } else {
      if (this.state.password === this.state.confirmPassword) {
        this.setState({ isLoading: true });
        axiosClient()
          .post(`/resetPassword`, loginDetails)
          .then((response) => {
            // console.log(response);
            this.setState({ isLoading: false });
            toast.success(response.data.message);
            setTimeout(() => this.props.history.push(`/`), 2000);
          })
          .catch((error) => {
            // console.log(error);
            this.setState({ isLoading: false });
            toast.error(error.response.data.message);
          });
      } else {
        toast.error("password must be same");
      }
    }
  };
  render() {
    return (
      <>
        <SimpleHeader />

        <ToastContainer autoClose={2000} />
        <Container>
          <Row style={{ justifyContent: "center" }}>
            <Col sm={10} md={5}>
              <div className={classes.email_div}>
                <h4 className={classes.logo}>Reset Password</h4>

                <Card className={classes.forget_card}>
                  <Card.Title className={classes.forget_heading}>
                    Set a new password for your account
                  </Card.Title>
                  <Form>
                    <Form.Group
                      style={{ position: "relative" }}
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        name="password"
                        className={classes.forget_form_control}
                        value={this.state.password}
                        onChange={this.onChangeEmail}
                      />
                      <span className={classes.form_design}>
                        <LockOutlinedIcon />
                      </span>
                    </Form.Group>
                  </Form>
                  <Form>
                    <Form.Group
                      style={{ position: "relative" }}
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="password"
                        placeholder="Confirm new password"
                        name="confirmPassword"
                        className={classes.forget_form_control}
                        value={this.state.confirmPassword}
                        onChange={this.onChangeEmail}
                      />
                      <span className={classes.form_design}>
                        <LockOutlinedIcon />
                      </span>
                    </Form.Group>
                  </Form>

                  <Button
                    onClick={this.handleForgetEmail}
                    className={classes.forgetbtn}
                    style={{ boxShadow: "none" }}
                    type="submit"
                    disabled={this.state.isLoading ? true : false}
                  >
                    {this.state.isLoading === true ? (
                      <span className="d-flex align-items-center justify-content-center">
                        <span className="mr-2">Please wait</span>
                        <CircularProgress
                          className="text-white"
                          color="inherit"
                          size={18}
                        />
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default ResetPassword;
