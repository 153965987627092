import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import classes from "./CompleteProfile.module.css";

class CompleteProfile extends Component {
  render() {
    return (
      <>
        <ToastContainer autoClose={3000} />
        <div className={classes.completeprofile_headings}>
          <p>Step 2 of 2</p>
          <h4>Complete Your Profile</h4>
          <span className="text-muted">
            Fill this form to complete your registeration
          </span>
        </div>
        <Form>
          <Form.Group controlId="name" required>
            <Form.Label className={classes.form_labels}>
              Name <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              className={classes.completeprofile_form_control}
              value={this.props.name}
              onChange={this.props.onChangeName}
            />
            {this.props.nameErr ? (
              <span className={classes.error_display}>
                {this.props.nameErr}
              </span>
            ) : null}
          </Form.Group>
          <Form.Group controlId="formEmail" required>
            <Form.Label className={classes.form_labels}>
              Email <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              className={classes.completeprofile_form_control}
              value={this.props.email}
              onChange={this.props.onChangeEmail}
            />
            {this.props.emailErr ? (
              <span className={classes.error_display}>
                {this.props.emailErr}
              </span>
            ) : null}
          </Form.Group>
          <Form.Group required>
            <Form.Label className={classes.form_labels}>
              Company/Merchant Name <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="company name"
              className={classes.completeprofile_form_control}
              value={this.props.shopName}
              onChange={this.props.onChangeShopName}
            />
            {this.props.shopNameErr ? (
              <span className={classes.error_display}>
                {this.props.shopNameErr}
              </span>
            ) : null}
          </Form.Group>
          <Form.Group required>
            <Form.Label className={classes.form_labels}>
              Password <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              type="password"
              className={classes.completeprofile_form_control}
              value={this.props.password}
              onChange={this.props.onChangePassword}
            />
            {this.props.passwordErr ? (
              <span className={classes.error_display}>
                {this.props.passwordErr}
              </span>
            ) : null}
          </Form.Group>
          <Form.Group controlId="formConfirmPassword" required>
            <Form.Label className={classes.form_labels}>
              Confirm Password <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              type="password"
              className={classes.completeprofile_form_control}
              value={this.props.confirmpassword}
              onChange={this.props.onChangeConfirmPassword}
            />
            {this.props.confirmPasswordErr ? (
              <span className={classes.error_display}>
                {this.props.confirmPasswordErr}
              </span>
            ) : null}
          </Form.Group>
        </Form>
      </>
    );
  }
}
export default CompleteProfile;
