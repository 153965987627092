import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import SideBar from "../../components/sidebar/Sidebar";
import axiosClient from "../../helper";
import VerifyCard from "../../components/verification/verifyCard";

import "./VerifyIdCard.css";

const VerifyIdCard = () => {
  const [isUpload, setIsUpload] = useState(false);
  const [isUploadBack, setIsUploadBack] = useState(false);
  const [cardVerify, setCardVerify] = useState({
    businessAddress: "",
    companyAddress: "",
    fullName: "",
    cnicNumber: "",
  });
  const [frontId, setFrontID] = useState("");
  const [backId, setBackId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axiosClient()
      .get(`/getUsersById/${localStorage.getItem("sellerId")}`)
      .then((response) => {
        setCardVerify({
          ...cardVerify,
          businessAddress: response.data.user.shopName,
          companyAddress: response.data.user.address,
          fullName: response.data.user.fullName,
          cnicNumber: response.data.user.IdCardNumber,
        });
        setFrontID(
          response.data.user.IdCardFrontImage
            ? response.data.user.IdCardFrontImage
            : ""
        );
        setBackId(
          response.data.user.IdCardBackImage
            ? response.data.user.IdCardBackImage
            : ""
        );
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }, []);

  const handleIdData = (event) => {
    setCardVerify({ ...cardVerify, [event.target.name]: event.target.value });
  };
  const handleFrontId = async (event) => {
    const files = event.target.files;
    setIsUpload(true);
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "hammad");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dz5fltj9r/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    setFrontID(file.secure_url);
    setIsUpload(false);
  };
  const handleBackId = async (event) => {
    const files = event.target.files;
    setIsUploadBack(true);
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "hammad");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dz5fltj9r/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    setBackId(file.secure_url);
    setIsUploadBack(false);
  };
  const removeImage = () => {
    setFrontID("");
  };
  const removeBackImage = () => {
    setBackId("");
  };
  const handleVerifyId = () => {
    let cnicInfo = {
      shopName: cardVerify.businessAddress,
      address: cardVerify.companyAddress,
      fullName: cardVerify.fullName,
      IdCardNumber: cardVerify.cnicNumber,
      IdCardFrontImage: frontId,
      IdCardBackImage: backId,
    };
    if (
      !cardVerify.businessAddress ||
      !cardVerify.companyAddress ||
      !cardVerify.fullName ||
      !cardVerify.cnicNumber ||
      !frontId ||
      !backId
    ) {
      toast.error("All fields are required");
    } else if (cardVerify.cnicNumber.replace(/-|_/g, "").length < 13) {
      toast.error("Cnic number in invalid");
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/verifySeller", cnicInfo)
        .then((response) => {
          toast.success("Details updated");
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <SideBar />
      <div className="m-div">
        <div className="verifyid_heading">Update ID Card Details</div>
        <VerifyCard
          cardVerify={cardVerify}
          handleVerifyId={handleVerifyId}
          frontId={frontId}
          isUpload={isUpload}
          isUploadBack={isUploadBack}
          backId={backId}
          removeImage={removeImage}
          removeBackImage={removeBackImage}
          handleIdData={handleIdData}
          handleFrontId={handleFrontId}
          handleBackId={handleBackId}
          isUpdate={true}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default VerifyIdCard;
