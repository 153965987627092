import React, { Component } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import ReactInputMask from "react-input-mask";

import "./VerifyCard.css";

class OrderProcess extends Component {
  render() {
    return (
      <div className="verify_idcard">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Form className="verifyid_form">
              <h3 className="verify_subheading">Order Processing Info</h3>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={12}>
                  How would you confirm order receipt?
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    name="orderReceiptConfirmation"
                    value={this.props.processOrder.orderReceiptConfirmation}
                    onChange={this.props.handleOrderProcess}
                  >
                    <option value="" disabled />
                    {this.props.processOrder.orderProcess.map((receipt) => (
                      <option key={receipt} value={receipt}>
                        {receipt}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={12}>
                  How would you confirm order delivery?
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    name="orderDeliveryConfirmation"
                    value={this.props.processOrder.orderDeliveryConfirmation}
                    onChange={this.props.handleOrderProcess}
                  >
                    <option value="" disabled />
                    {this.props.processOrder.orderProcess.map((delivery) => (
                      <option key={delivery} value={delivery}>
                        {delivery}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={12}>
                  Email
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="email"
                    name="email"
                    value={this.props.processOrder.email}
                    onChange={this.props.handleOrderProcess}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={12}>
                  Phone #
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <ReactInputMask
                    mask={"+\\92 999 9999999"}
                    alwaysShowMask={false}
                    type="text"
                    name="phoneNo"
                    value={this.props.processOrder.phoneNo}
                    onChange={this.props.handleOrderProcess}
                  >
                    {() => (
                      <Form.Control
                        name="phoneNo"
                        value={this.props.processOrder.phoneNo}
                      />
                    )}
                  </ReactInputMask>
                </Col>
              </Form.Group>
            </Form>
          </Grid>
        </Grid>
        <div className="float-right add-update-btn-m common-buttons">
          <Button
            className="d-flex align-items-center"
            onClick={this.props.handleAddOrderProcess}
            disabled={this.props.isLoading ? true : false}
          >
            {this.props.isLoading ? (
              <>
                <span className="mr-3">Please wait</span>
                <CircularProgress color="inherit" size={20} />
              </>
            ) : this.props.isUpdate ? (
              "Update Details"
            ) : (
              "Add Details"
            )}
          </Button>
        </div>
        <div
          className={
            this.props.isBackBtn ? "float-right mr-1 common-buttons" : "d-none"
          }
        >
          <Button onClick={this.props.handleBack}>Back</Button>
        </div>
      </div>
    );
  }
}
export default OrderProcess;
