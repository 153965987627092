import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import SideBar from "../../components/sidebar/Sidebar";
import axiosClient from "../../helper";
import VerifyAccount from "../../components/verification/verifyAccount";

import "./VerifyBankAccount.css";

const VerifyBankAccount = () => {
  const [accountVerify, setAccountVerify] = useState({
    accountTitle: "",
    accountNumber: "",
    selectedBank: "Allied Bank Limited",
    branchCode: "",
    bankName: [
      "Allied Bank Limited",
      "Al Baraka Bank Limited",
      "Askari Bank",
      "Bank Alfalah Limited",
      "Bank Al-Habib Limited",
      "BankIslami Pakistan Limited",
      "Citi Bank",
      "Deutsche Bank A.G",
      "The Bank of Tokyo-Mitsubishi UFJ",
      "Dubai Islamic Bank Pakistan Limited",
      "Faysal Bank Limited",
      "First Women Bank Limited",
      "Habib Bank Limited",
      "Standard Chartered Bank Limited",
      "Habib Metropolitan Bank Limited",
      "Industrial and Commercial Bank of China",
      "Industrial Development Bank of Pakistan",
      "JS Bank Limited",
      "MCB Bank Limited",
      "MCB Islamic Bank Limited",
      "Meezan Bank Limited",
      "National Bank of Pakistan",
    ],
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axiosClient()
      .get(`/getBankingDetailsOfUser`)
      .then((response) => {
        setAccountVerify({
          ...accountVerify,
          accountTitle: response.data.bankingDetails.accountTitle,
          accountNumber: response.data.bankingDetails.accountNumber,
          selectedBank: response.data.bankingDetails.bankName,
          branchCode: response.data.bankingDetails.branchCode,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleAccountData = (event) => {
    setAccountVerify({
      ...accountVerify,
      [event.target.name]: event.target.value,
    });
  };
  const handleAddBankDetails = () => {
    let accountData = {
      accountTitle: accountVerify.accountTitle,
      accountNumber: accountVerify.accountNumber,
      bankName: accountVerify.selectedBank,
      branchCode: accountVerify.branchCode,
    };
    if (
      accountVerify.accountTitle === "" ||
      accountVerify.accountNumber === "" ||
      accountVerify.selectedBank === "" ||
      accountVerify.branchCode === ""
    ) {
      toast.error("All fields are required, fill all to proceed");
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/createBankingDetailsByUserId", accountData)
        .then((response) => {
          toast.success("Details updated");
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <SideBar />
      <div className="m-div">
        <div className="verifybank_heading">Update Bank Account Details</div>
        <VerifyAccount
          accountVerify={accountVerify}
          handleAddBankDetails={handleAddBankDetails}
          handleAccountData={handleAccountData}
          isUpdate={true}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default VerifyBankAccount;
