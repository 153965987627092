import React, { useState } from "react";
import { Link } from "react-router-dom";

import { IconContext } from "react-icons/lib";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { RiLogoutBoxRLine } from "react-icons/ri";

import iownLogo from "../../assets/iownLogoSignin.png";

import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";

import "./sidebar.css";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const logout = () => {
    localStorage.clear();
    window.location = "/";
  };
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="for_desktop">
          <div className="navs_for_desktop">
            <div className="logout" onClick={logout}>
              <label style={{ cursor: "pointer" }}>Logout</label>{" "}
              <RiLogoutBoxRLine style={{ color: "#4574aa" }} />
            </div>
          </div>
          <nav className="sidebar_nav">
            <div className="sidebar_wrap">
              <Link to="/dashboard" className="links">
                {/* <h4>Deosai</h4> */}
                <img src={iownLogo} alt="iown.pk" className="iownLogo" />
              </Link>
              <div className="sidebar_links_div">
                {SidebarData.map((item, index) => {
                  return <SubMenu item={item} key={index} />;
                })}
              </div>
            </div>
          </nav>
        </div>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="for_mobile">
          <div className="navs">
            <Link to="#" className="links">
              <FaBars onClick={showSidebar} style={{ color: "#626161" }} />
            </Link>
          </div>
          {/* left: ${({ sidebar }) => (sidebar ? "0" : "-100%")}; */}
          <nav
            // sidebar={sidebar}
            className={sidebar ? "show_sidebar" : "hide_sidebar"}
          >
            <div className="sidebar_wrap">
              <Link to="#" className="links">
                <AiOutlineClose onClick={showSidebar} />
              </Link>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </div>
          </nav>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
