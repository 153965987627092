import React, { Component } from "react";
import "./Dashboard.css";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SideBar from "../../components/sidebar/Sidebar";
import { axiosClient, formatter } from "../../helper";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProducts: 0,
      inactiveProducts: 0,
      greaterThan24h: 0,
      lessThan12h: 0,
      totalOrders: 0,
      statisticsDuration: "All Time",
      totalReturnOrders: 0,
      totalCustomers: 0,
      returnRequestPending: 0,
      totalSales: 0.0,
      products: [],
    };
  }

  componentDidMount() {
    this.getProductsBySellerId();
    this.getOrdersBySellerId();
    this.getCustomersBySellerId();
    this.getReturnRequests();
  }

  getReturnRequests = () => {
    const id = localStorage.getItem("sellerId");
    axiosClient()
      .get(`/getReturnRequestsBySellerId/${id}`)
      .then((response) => {
        this.returnRequestStats(response.data.returnRequests);
      })
      .catch((error) => {
        // console.log("error in view return requests:", error);
      });
  };

  getCustomersBySellerId = () => {
    const id = localStorage.getItem("sellerId");
    axiosClient()
      .get(`/getBuyersBySellerAndOrder/${id}`)
      .then((response) => {
        let buyers = response.data.buyers ? response.data.buyers : [];
        var result;
        if (buyers.length > 0) {
          result = buyers.reduce((unique, o) => {
            if (!unique.some((obj) => obj._id === o._id)) {
              unique.push(o);
            }
            return unique;
          }, []);
        }

        if (result) {
          this.setState({ totalCustomers: result.length });
        }
      })
      .catch((error) => {
        // console.log("error :", error);
      });
  };
  getProductsBySellerId = () => {
    const id = localStorage.getItem("sellerId");
    axiosClient()
      .get(`/getProductsBySellerId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          this.getProductStats(response.data.products);
        }
      })
      .catch((error) => {
        // console.log("error in products:", error);
      });
  };

  getOrdersBySellerId = () => {
    const id = localStorage.getItem("sellerId");
    axiosClient()
      .get(`/getOrderBySellerId/${id}`)
      .then((res) => {
        if (res.status === 200) {
          this.getOrdersStats(res.data.orders);
        }
      })
      .catch((error) => {
        // console.log("error in view orders:", error);
      });
  };

  getOrdersStats = (orders) => {
    let totalOrders = 0;
    let ordersLess12 = 0;
    let ordersGreater24 = 0;
    let totalSales = 0;
    let currentTime = new Date().getTime();

    orders?.forEach((element) => {
      totalOrders = totalOrders + 1;
      totalSales = totalSales + element.orderId.totalPrice;
      let time = new Date(element.orderId.createdAt).getTime();
      let diff = (time - currentTime) / 1000;
      diff /= 60 * 60;
      diff = Math.abs(Math.round(diff));
      if (diff < 12) {
        ordersLess12 = ordersLess12 + 1;
      } else if (diff > 24) {
        ordersGreater24 = ordersGreater24 + 1;
      }
    });

    this.setState({
      totalOrders: totalOrders,
      lessThan12h: ordersLess12,
      greaterThan24h: ordersGreater24,
      totalSales: totalSales,
    });
  };

  getProductStats = (products) => {
    let countActive = 0;
    let countInactive = 0;

    products?.forEach((element) => {
      if (element.active === true) {
        countActive = countActive + 1;
      } else if (element.active === false) {
        countInactive = countInactive + 1;
      }
    });

    this.setState({
      activeProducts: countActive,
      inactiveProducts: countInactive,
    });
  };
  returnRequestStats = (returnRequests) => {
    let pendingRequests = 0;
    let totalRequests = 0;

    returnRequests?.forEach((element) => {
      totalRequests = totalRequests + 1;
      if (element.status === "Awaiting") {
        pendingRequests = pendingRequests + 1;
      }
    });

    this.setState({
      totalReturnOrders: totalRequests,
      returnRequestPending: pendingRequests,
    });
  };
  render() {
    return (
      <>
        <SideBar />
        <div className="dashboard_div">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {" "}
              <div className="completion_steps">
                <div className="text_div">
                  <span>Welcome! Complete Your Profile.</span>
                  <span className="completed_text">Keep up the good work!</span>
                </div>
                <Grid container spacing={5} className="cards_container">
                  <Grid item lg={3} md={4} sm={4} xs={6}>
                    <Link to="/verify-id">
                      <Card>
                        <Card.Header>Safeguard your account</Card.Header>
                        <Card.Body>Verify ID Information</Card.Body>
                      </Card>
                    </Link>
                  </Grid>
                  <Grid item lg={3} md={4} sm={4} xs={6}>
                    <Link to="/verify-bankaccount">
                      <Card>
                        <Card.Header>To receive your money</Card.Header>
                        <Card.Body>Add Payment Informaion</Card.Body>
                      </Card>
                    </Link>
                  </Grid>
                  <Grid item lg={3} md={4} sm={4} xs={6}>
                    <Link to="/contact-details">
                      <Card>
                        <Card.Header>Contact Details</Card.Header>
                        <Card.Body>Provide Contact Information</Card.Body>
                      </Card>
                    </Link>
                  </Grid>
                  <Grid item lg={3} md={4} sm={4} xs={6}>
                    <Link to="/orderprocess-info">
                      <Card>
                        <Card.Header>Order Processing</Card.Header>
                        <Card.Body>Order Processing Info</Card.Body>
                      </Card>
                    </Link>
                  </Grid>
                </Grid>
              </div>
              <Grid
                container
                spacing={2}
                style={{ marginTop: "0.2%", marginBottom: "2%" }}
              >
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="performance_div">
                    <Card>
                      <Card.Header>Operation</Card.Header>
                      <Card.Body>
                        <h5>Products</h5>
                        <Grid container spacing={3}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className="analytics_divs">
                              <label>Active Products</label>
                              <label>{this.state.activeProducts}</label>
                            </div>
                            <div className="analytics_divs">
                              <label>Inactive Products</label>
                              <label>{this.state.inactiveProducts}</label>
                            </div>
                          </Grid>
                        </Grid>
                        <div
                          className="analytics_divs"
                          style={{ paddingBottom: "2%" }}
                        >
                          <h5> Orders</h5>
                        </div>
                        <Grid container spacing={3}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className="analytics_divs">
                              <label>{`Since > 24h`}</label>
                              <label>{this.state.greaterThan24h}</label>
                            </div>
                            <div className="analytics_divs">
                              <label>{`Since < 12h`}</label>
                              <label>{this.state.lessThan12h}</label>
                            </div>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className="analytics_divs">
                              <label>Total Orders</label>
                              <label>{this.state.totalOrders}</label>
                            </div>
                          </Grid>
                        </Grid>
                        <div
                          className="analytics_divs"
                          style={{ paddingBottom: "2%" }}
                        >
                          <h5>Pending Return Orders</h5>
                        </div>
                        <Grid container spacing={3}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className="analytics_divs">
                              <label>Return request pending</label>
                              <label>{this.state.returnRequestPending}</label>
                            </div>
                          </Grid>
                        </Grid>
                      </Card.Body>
                    </Card>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="sellerpicks_chat_div">
                    <Card>
                      <Card.Header
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Seller Picks{" "}
                        {/* <Form.Control
                          as="select"
                          className="stats_duration"
                          defaultValue={this.state.statisticsDuration}
                          onChange={(e) =>
                            this.setState({
                              statisticsDuration: e.target.value,
                            })
                          }
                        >
                          <option value="All Time">All Time</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Daily">Daily</option>
                        </Form.Control> */}
                      </Card.Header>
                      <Card.Body>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className="picks_chat_analytics">
                              <label>Total Sales</label>
                              <span>
                                {formatter.format(this.state.totalSales)}
                              </span>
                            </div>
                            <div className="picks_chat_analytics">
                              <label>Total Orders</label>
                              <span>{this.state.totalOrders}</span>
                            </div>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className="picks_chat_analytics">
                              <label>Total Return Orders</label>
                              <span>{this.state.totalReturnOrders}</span>
                            </div>
                            <div className="picks_chat_analytics">
                              <label>Total Customers</label>
                              <span>{this.state.totalCustomers}</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Card.Body>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}
export default Dashboard;
