import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { toast } from "react-toastify";

import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import VerifyCard from "../../components/verification/verifyCard";
import VerifyAccount from "../../components/verification/verifyAccount";
import ContectDetail from "../../components/verification/contectDetail";
import OrderProcess from "../../components/verification/orderProcessing";

import axiosClient from "../../helper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "10px",
    marginBottom: "30px",
  },

  backButton: {
    marginRight: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  verify_card: {
    paddingLeft: "15px",
    paddingRight: "15px",
    width: "75%",
    margin: "auto",
    marginTop: "28px",
  },
}));

export default function DashboardFront() {
  const classes = useStyles();
  const steps = getSteps();

  const [activeStep, setActiveStep] = React.useState(0);

  const [frontId, setFrontID] = useState([]);
  const [backId, setBackId] = useState([]);

  const [cardVerify, setCardVerify] = useState({
    businessAddress: "",
    companyAddress: "",
    fullName: "",
    cnicNumber: "",
  });

  const [isUpload, setIsUpload] = useState(false);
  const [isUploadBack, setIsUploadBack] = useState(false);

  const [accountVerify, setAccountVerify] = useState({
    accountTitle: "",
    accountNumber: "",
    selectedBank: "Allied Bank Limited",
    branchCode: "",
    bankName: [
      "Allied Bank Limited",
      "Al Baraka Bank Limited",
      "Askari Bank",
      "Bank Alfalah Limited",
      "Bank Al-Habib Limited",
      "BankIslami Pakistan Limited",
      "Citi Bank",
      "Deutsche Bank A.G",
      "The Bank of Tokyo-Mitsubishi UFJ",
      "Dubai Islamic Bank Pakistan Limited",
      "Faysal Bank Limited",
      "First Women Bank Limited",
      "Habib Bank Limited",
      "Standard Chartered Bank Limited",
      "Habib Metropolitan Bank Limited",
      "Industrial and Commercial Bank of China",
      "Industrial Development Bank of Pakistan",
      "JS Bank Limited",
      "MCB Bank Limited",
      "MCB Islamic Bank Limited",
      "Meezan Bank Limited",
      "National Bank of Pakistan",
    ],
  });

  const [detailContact, setDetailContact] = useState({
    primaryName: "",
    primaryPhoneNo: "",
    primaryAddress: "",
    secondaryName: "",
    secondaryPhoneNo: "",
    secondaryAddress: "",
  });

  const [processOrder, setProcessOrder] = useState({
    orderProcess: ["Email", "Call", "Whatsapp"],
    orderReceiptConfirmation: "Email",
    orderDeliveryConfirmation: "Email",
    email: "",
    phoneNo: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const removeImage = () => {
    setFrontID("");
  };
  const removeBackImage = () => {
    setBackId("");
  };

  useEffect(() => {
    axiosClient()
      .get(`/getUsersById/${localStorage.getItem("sellerId")}`)
      .then((response) => {
        setCardVerify({
          ...cardVerify,
          businessAddress: response.data.user.shopName,
          companyAddress: response.data.user.address,
          fullName: response.data.user.fullName,
          cnicNumber: response.data.user.IdCardNumber,
        });

        setDetailContact({
          ...detailContact,
          primaryName: response.data.user.primaryContactName,
          primaryPhoneNo: response.data.user.primaryContactPhone,
          primaryAddress: response.data.user.primaryContactAddress,
          secondaryName: response.data.user.secondaryContactName,
          secondaryPhoneNo: response.data.user.secondaryContactPhone,
          secondaryAddress: response.data.user.secondaryContactAddress,
        });

        setProcessOrder({
          ...processOrder,
          orderDeliveryConfirmation: response.data.user
            .sellerOrderReceiptConfirmation
            ? response.data.user.sellerOrderReceiptConfirmation
            : "Email",
          orderReceiptConfirmation: response.data.user
            .sellerOrderReceiptConfirmation
            ? response.data.user.sellerOrderReceiptConfirmation
            : "Email",
          email: response.data.user.sellerOrderEmail,
          phoneNo: response.data.user.sellerOrderPhone,
        });

        setFrontID(
          response.data.user.IdCardFrontImage
            ? response.data.user.IdCardFrontImage
            : ""
        );
        setBackId(
          response.data.user.IdCardBackImage
            ? response.data.user.IdCardBackImage
            : ""
        );
      })
      .catch((error) => {
        // console.log(error);
      });

    axiosClient()
      .get(`/getBankingDetailsOfUser`)
      .then((response) => {
        if (response.data.bankingDetails) {
          setAccountVerify({
            ...accountVerify,
            accountTitle: response.data.bankingDetails?.accountTitle,
            accountNumber: response.data.bankingDetails?.accountNumber,
            selectedBank: response.data.bankingDetails?.bankName,
            branchCode: response.data.bankingDetails?.branchCode,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }, []);

  const handleVerifyId = () => {
    let cnicInfo = {
      shopName: cardVerify.businessAddress,
      address: cardVerify.companyAddress,
      fullName: cardVerify.fullName,
      IdCardNumber: cardVerify.cnicNumber,
      IdCardFrontImage: frontId,
      IdCardBackImage: backId,
    };
    if (
      !cardVerify.businessAddress ||
      !cardVerify.companyAddress ||
      !cardVerify.fullName ||
      !cardVerify.cnicNumber ||
      !frontId ||
      !backId
    ) {
      toast.error("Please fill all required fields to proceed");
    } else if (cardVerify.cnicNumber.replace(/-|_/g, "").length < 13) {
      toast.error("Cnic number in invalid");
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/verifySeller", cnicInfo)
        .then((response) => {
          toast.success("Details added");
          setIsLoading(false);
          handleNext();
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
          setIsLoading(false);
        });
    }
  };

  const handleAddBankDetails = () => {
    let accountData = {
      accountTitle: accountVerify.accountTitle,
      accountNumber: accountVerify.accountNumber,
      bankName: accountVerify.selectedBank,
      branchCode: accountVerify.branchCode,
    };
    if (
      accountVerify.accountTitle === "" ||
      accountVerify.accountNumber === "" ||
      accountVerify.selectedBank === "" ||
      accountVerify.branchCode === ""
    ) {
      toast.error("All fields are required, fill all to proceed");
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/createBankingDetailsByUserId", accountData)
        .then((response) => {
          toast.success("Details added");
          setIsLoading(false);
          handleNext();
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
          setIsLoading(false);
        });
    }
  };

  const handleAddColleagueInfo = () => {
    let colleagueInfo = {
      primaryContactName: detailContact.primaryName,
      primaryContactPhone: detailContact.primaryPhoneNo,
      primaryContactAddress: detailContact.primaryAddress,
      secondaryContactName: detailContact.secondaryName,
      secondaryContactPhone: detailContact.secondaryPhoneNo,
      secondaryContactAddress: detailContact.secondaryAddress,
    };
    if (
      !detailContact.primaryName ||
      !detailContact.primaryPhoneNo ||
      !detailContact.primaryAddress ||
      !detailContact.secondaryName ||
      !detailContact.secondaryPhoneNo ||
      !detailContact.secondaryAddress
    ) {
      toast.error("All fields are required");
    } else if (detailContact.primaryPhoneNo.replace(/-|_/g, "").length < 15) {
      toast.error("Primary phone # is invalid");
    } else if (detailContact.secondaryPhoneNo.replace(/-|_/g, "").length < 15) {
      toast.error("Secondary phone # is invalid");
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/colleagueInformationBySeller", colleagueInfo)
        .then((response) => {
          toast.success("Details added");
          setIsLoading(false);
          handleNext();
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
          setIsLoading(false);
        });
    }
  };

  const handleAddOrderProcess = () => {
    let orderProcess = {
      orderReceiptConfirmation: processOrder?.orderReceiptConfirmation,
      orderDeliveryConfirmation: processOrder?.orderDeliveryConfirmation,
      sellerOrderEmail: processOrder?.email,
      sellerOrderPhone: processOrder?.phoneNo,
    };
    if (!processOrder.email && !processOrder.phoneNo) {
      toast.error("Please provide either phone no or email");
    } else if (
      processOrder.phoneNo &&
      processOrder.phoneNo.replace(/-|_/g, "").length < 15
    ) {
      toast.error("Phone number is invalid");
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/sellerOrderProcessing", orderProcess)
        .then((response) => {
          toast.success("Details added");
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
          setIsLoading(false);
        });
    }
  };

  const handleIdData = (event) => {
    setCardVerify({ ...cardVerify, [event.target.name]: event.target.value });
  };
  const handleAccountData = (event) => {
    setAccountVerify({
      ...accountVerify,
      [event.target.name]: event.target.value,
    });
  };
  const handleContactData = (event) => {
    setDetailContact({
      ...detailContact,
      [event.target.name]: event.target.value,
    });
  };
  const handleOrderProcess = (event) => {
    setProcessOrder({
      ...processOrder,
      [event.target.name]: event.target.value,
    });
  };
  const handleFrontId = async (event) => {
    const files = event.target.files;
    setIsUpload(true);
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "hammad");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dz5fltj9r/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    setFrontID(file.secure_url);
    setIsUpload(false);
  };
  const handleBackId = async (event) => {
    const files = event.target.files;
    setIsUploadBack(true);
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "hammad");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dz5fltj9r/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    setBackId(file.secure_url);
    setIsUploadBack(false);
  };

  function getSteps() {
    return [
      "ID Information",
      "Payment Information",
      "Contacts Details",
      "Order Processing Info",
    ];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <VerifyCard
            handleVerifyId={handleVerifyId}
            handleBack={handleBack}
            cardVerify={cardVerify}
            frontId={frontId}
            backId={backId}
            handleBackId={handleBackId}
            handleFrontId={handleFrontId}
            isUpload={isUpload}
            isUploadBack={isUploadBack}
            handleIdData={handleIdData}
            handlenext={handleNext}
            removeImage={removeImage}
            removeBackImage={removeBackImage}
            isLoading={isLoading}
          />
        );
      case 1:
        return (
          <VerifyAccount
            handleAddBankDetails={handleAddBankDetails}
            accountVerify={accountVerify}
            handlenext={handleNext}
            handleAccountData={handleAccountData}
            handleBack={handleBack}
            isBackBtn={true}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <ContectDetail
            handleAddColleagueInfo={handleAddColleagueInfo}
            handleContactData={handleContactData}
            detailContact={detailContact}
            handleBack={handleBack}
            handlenext={handleNext}
            isBackBtn={true}
            isLoading={isLoading}
          />
        );
      case 3:
        return (
          <OrderProcess
            handleAddOrderProcess={handleAddOrderProcess}
            handleOrderProcess={handleOrderProcess}
            processOrder={processOrder}
            handleBack={handleBack}
            isBackBtn={true}
            isLoading={isLoading}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <React.Fragment>
      <div>
        <SimpleHeader />
      </div>

      <div className={classes.root}>
        <Stepper
          style={{ background: "transparent" }}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.verify_card}>{getStepContent(activeStep)}</div>
      </div>
    </React.Fragment>
  );
}
