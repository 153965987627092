// import React from "react";
import { axiosClient } from "../../../helper";
import { toast } from "react-toastify";

export const addProduct = (productData) => {
  return axiosClient()
    .post("/createProduct", productData)
    .then((response) => {
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error.response?.data.message);
    });
};

export const addTags = (name) => {
  return axiosClient()
    .post("/addTagName", name)
    .then((response) => {})
    .catch((error) => {
      console.log(error.response?.data.message);
    });
};

export const updateProduct = (productData, id) => {
  return axiosClient()
    .put(`/updateProduct/${id}`, productData)
    .then((response) => {
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      toast.error(error.response?.data.message);
    });
};

export const getCategories = (cat) => {
  axiosClient()
    .get("/getCategories")
    .then((response) => {
      cat = response.data.categories;
    })
    .catch((error) => {
      // console.log(error);
    });
};
