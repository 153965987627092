import React, { Component } from "react";
import "./AddressBook.css";
import SideBar from "../../components/sidebar/Sidebar";
import AddressBookForm from "../../components/addressBookForm/AddressBookForm";

class AddressBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      states: [
        { value: "azad-kashmir", name: "Azad Kashmir" },
        { value: "balochistan", name: "Balochistan" },
        { value: "fata", name: "Federally Administered Tribal Areas" },
        { value: "gilgit-baltistan", name: "Gilgit-Baltistan" },
        { value: "islamabad", name: "Islamabad" },
        { value: "khyber-paktunkhwa", name: "Khyber Paktunkhwa" },
        { value: "punjab", name: "Punjab" },
        { value: "sindh", name: "Sindh" },
      ],
      locations: [
        { value: "lahore", name: "Lahore" },
        { value: "islamabad", name: "Islamabad" },
        { value: "karachi", name: "Karachi" },
        { value: "multan", name: "Multan" },
        { value: "quetta", name: "Quetta" },
      ],
      areas: [
        { value: "askari", name: "Askari" },
        { value: "dha", name: "DHA" },
        { value: "bahria", name: "Bahria" },
      ],
    };
  }
  onChangeWarehouseAddress = (e) => {
    this.setState({ address: e.target.value });
  };
  onChangeBusinessAddress = (e) => {
    this.setState({ address: e.target.value });
  };
  onChangeReturnAddress = (e) => {
    this.setState({ address: e.target.value });
  };
  onChangeWarehouseState = (e) => {
    this.setState({ states: e.target.value });
  };
  onChangeBusinessState = (e) => {
    this.setState({ states: e.target.value });
  };
  onChangeReturnState = (e) => {
    this.setState({ states: e.target.value });
  };
  onChangeWarehouseArea = (e) => {
    this.setState({ states: e.target.value });
  };
  onChangeBusinessArea = (e) => {
    this.setState({ states: e.target.value });
  };
  onChangeReturnArea = (e) => {
    this.setState({ states: e.target.value });
  };
  onChangeWarehouseLocation = (e) => {
    this.setState({ states: e.target.value });
  };
  onChangeBusinessLocation = (e) => {
    this.setState({ states: e.target.value });
  };
  onChangeReturnLocation = (e) => {
    this.setState({ states: e.target.value });
  };
  render() {
    return (
      <>
        <SideBar />
        <div className="address_heading">Address Book</div>
        <div className="address_book_div">
          <AddressBookForm
            addressHeading="Warehouse Address"
            address={this.state.address}
            states={this.state.states}
            locations={this.state.locations}
            areas={this.state.areas}
            onChangeAddress={this.onChangeWarehouseAddress}
            onChangeState={this.onChangeWarehouseState}
            onChangeArea={this.onChangeWarehouseArea}
            onChangeLocation={this.onChangeWarehouseLocation}
          />
          <div style={{ marginTop: "3%" }}>
            <AddressBookForm
              addressHeading="Business Address"
              address={this.state.address}
              states={this.state.states}
              locations={this.state.locations}
              areas={this.state.areas}
              onChangeAddress={this.onChangeBusinessAddress}
              onChangeState={this.onChangeBusinessState}
              onChangeArea={this.onChangeBusinessArea}
              onChangeLocation={this.onChangeBusinessLocation}
            />
          </div>
          <div style={{ marginTop: "3%" }}>
            <AddressBookForm
              addressHeading="Return Address"
              address={this.state.address}
              states={this.state.states}
              locations={this.state.locations}
              areas={this.state.areas}
              onChangeAddress={this.onChangeReturnAddress}
              onChangeState={this.onChangeReturnState}
              onChangeArea={this.onChangeReturnArea}
              onChangeLocation={this.onChangeReturnLocation}
              style={{ marginTop: "6%" }}
            />
          </div>
        </div>
      </>
    );
  }
}
export default AddressBook;
