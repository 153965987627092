import React, { Component } from "react";
import "./AddProduct.css";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Button } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ChipInput from "material-ui-chip-input";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddBoxIcon from "@material-ui/icons/AddBox";
import RemoveIcon from "@material-ui/icons/Remove";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SideBar from "../../components/sidebar/Sidebar";
import { addTags } from "./store/actions";
import { axiosClient } from "../../helper";

class AddProduct extends Component {
  state = {
    productName: "",
    type: "Product",
    location: [
      "Lahore",
      "Karachi",
      "Islamabad",
      "Multan",
      "Peshawar",
      "Sargodha",
      "Sukkur",
      "Quetta",
      "Muzaffarabad",
    ],
    timeline: ["Week", "Hour", "Day"],
    selectedLocations: ["All"],
    standardCompliance: "",
    category: [],
    subCategory: [],
    selectedCategory: "",
    selectedSubcategory: "",
    brands: [],
    selectedBrandId: "",
    selectedBrandName: "",
    price: "",
    countInStock: "",
    minimumQuantity: 1,
    sellEverywhere: true,
    productTags: [],
    deliveryAvailable: "Yes",
    isDeliveryAvailable: true,
    transportationCharges: "Yes",
    shipmentCharges: 0,
    isTransportationCharges: true,
    isMultipleAllow: true,
    averageRating: 4.5,
    description: "",
    transportationPolicy: "",
    returnPolicy: "",
    cancellationPolicy: "",
    breakagePolicy: "",
    weightDeviationPolicy: "",
    files: [],
    colors: [],
    colorFamily: "",
    productWeight: "",
    productDepth: "",
    productWidth: "",
    productHeight: "",
    productUpdates: "Manual",
    updateFrequency: "Daily",
    productFeatureAd: "Yes",
    isProductFeatureAd: true,
    adFrequency: "Week",
    active: true,
    productId: "",
    isNew: false,
    sellerName: "",
    err: false,
    validated: false,
    maximumQuantity: 0,
    chooseMultiple: "",
    chooseMultipleValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    isUpload: false,
    priceUnit: "",
    // sku: "",
    maxTransportationVolume: 0,
    allCities: [],

    inputLists: [
      {
        transportLocatiion: "",
        transportCharges: "",
      },
    ],
    deliveryInputFields: [
      {
        deliveryLocation: "",
        deliveryTime: "",
        deliveryTimeUnit: "",
      },
    ],

    categoryShortCode: "",
    subCategoryShortCode: "",
    brandShortCode: "",
    colorShortCode: "",

    isLoading: false,
  };

  modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["clean"],
      [{ header: [1, 2, 3, 4, 5, 6] }],
      [{ size: ["small", false, "large", "huge"] }],
    ],
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ productId: id });
    if (id === "new") {
      this.setState({ isNew: true });
    } else {
      this.setState({ isNew: false });
      this.getProductById(id);
    }
    this.getCategories();
    this.getAllBrands();
    this.getAllCities();
    this.getAllColors();
  }

  getAllCities = () => {
    axiosClient()
      .get(`/getAllCities`)
      .then((response) => {
        this.setState({ allCities: response.data.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getAllColors = () => {
    axiosClient()
      .get(`/getAllColors`)
      .then((response) => {
        this.setState({ colors: response.data.colors });
        if (this.state.isNew) {
          this.setState({ colorFamily: response.data.colors[0]?._id });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  getProductById = (id) => {
    axiosClient()
      .get(`/getProductById/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const product = response.data.product;
          this.setState({
            productName: product.name,
            files: product.image,
            selectedBrandId: product.brandId?._id,
            selectedBrandName: product.brand,
            price: product.price,
            priceUnit: product.priceUnit,
            countInStock: product.countInStock,
            // sku: product.SKU,
            maxTransportationVolume: product.maxTransportationVolume,
            productTags: product.productTags,
            minimumQuantity: product.minimumQuantity,
            maximumQuantity: product.maximumQuantity,
            isMultipleAllow: product.isMultipleAllow,
            chooseMultiple: product.chooseMultiple
              ? product.chooseMultiple
              : "",

            description: product.description ? product.description : "",
            averageRating: product.averageRating,
            selectedCategory: product.categoryId?._id,
            active: product.active,
            type: product.type,
            colorFamily: product.colorId?._id,
            standardCompliance: product.standardCompliance,
            selectedLocations: product.locationByCity,
            shipmentCharges: product.shippingCharges,
            isDeliveryAvailable: product.delivery,
            isTransportationCharges: product.transportationCharges,
            transportationPolicy: product.transportationPolicy
              ? product.transportationPolicy
              : "",
            returnPolicy: product.returnPolicy ? product.returnPolicy : "",
            cancellationPolicy: product.cancellationPolicy
              ? product.cancellationPolicy
              : "",
            breakagePolicy: product.breakingPolicy
              ? product.breakingPolicy
              : "",
            weightDeviationPolicy: product.weightDeviationPolicy
              ? product.weightDeviationPolicy
              : "",
            productUpdates: product.productUpdates,
            updateFrequency: product.updatesFrequency,
            isProductFeatureAd: product.productFeature,
            adFrequency: product.adFrequency,
            productWidth: product.productWidth,
            productHeight: product.productHeight,
            productDepth: product.productDepth,
            productWeight: product.productWeight,
            sellerName: product.sellerName,

            categoryShortCode: product.categoryId?.categoryShortCode,
            subCategoryShortCode: product.subCategoryId?.subCategoryShortCode,
            brandShortCode: product.brandId?.brandShortCode,
            colorShortCode: product.colorId?.colorShortCode,

            inputLists: product.transportCharges
              ? product.transportCharges
              : this.state.inputLists,
            deliveryInputFields: product.deliveryTimeLine
              ? product.deliveryTimeLine
              : this.state.deliveryInputFields,
          });

          this.getSubcategory(
            product.categoryId._id,
            product.subCategoryId?._id
          );

          if (product.locationByCity[0] === "All") {
            this.setState({ sellEverywhere: true });
          } else {
            this.setState({ sellEverywhere: false });
          }

          if (product.delivery === true) {
            this.setState({ deliveryAvailable: "Yes" });
          } else {
            this.setState({ deliveryAvailable: "No" });
          }

          if (product.transportationCharges === true) {
            this.setState({ transportationCharges: "Yes" });
          } else {
            this.setState({ transportationCharges: "No" });
          }

          if (product.productFeature === true) {
            this.setState({ productFeatureAd: "Yes" });
          } else {
            this.setState({ productFeatureAd: "No" });
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getCategories = () => {
    axiosClient()
      .get("/getCategories")
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            category: response.data.categories,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getSubcategory = (id, sid) => {
    axiosClient()
      .get(`/getSubCategoriesByCategoryId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            subCategory: response.data.subCategory,
          });
          this.setState({
            selectedSubcategory: sid ? sid : response.data.subCategory[0]._id,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  getAllBrands = () => {
    axiosClient()
      .get("/getAllBrands")
      .then((response) => {
        if (response.status === 200) {
          this.setState({ brands: response.data.brands });
          if (this.props.match.params.id === "new") {
            this.setState({
              selectedBrandId: response.data.brands[0]._id,
              selectedBrandName: response.data.brands[0].name,
              brandShortCode: response.data.brands[0].brandShortCode,
            });
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  handleChangeInput = (index, event) => {
    const values = [...this.state.inputLists];
    values[index][event.target.name] = event.target.value;
    this.setState({ inputLists: values });
  };

  handleDeliveryInput = (index, event) => {
    const values = [...this.state.deliveryInputFields];
    values[index][event.target.name] = event.target.value;
    this.setState({ deliveryInputFields: values });
  };

  handleAddDeliveryField = () => {
    this.setState({
      deliveryInputFields: [
        ...this.state.deliveryInputFields,
        { deliveryLocation: "", deliveryTime: "", deliveryTimeUnit: "" },
      ],
    });
  };
  handleAddField = () => {
    this.setState({
      inputLists: [
        ...this.state.inputLists,
        { transportLocatiion: "", transportCharges: "" },
      ],
    });
  };
  handleRemoveDeliveryField = (index) => {
    const values = [...this.state.deliveryInputFields];
    values.splice(index, 1);
    this.setState({ deliveryInputFields: values });
  };
  handleRemoveField = (index) => {
    const values = [...this.state.inputLists];
    values.splice(index, 1);
    this.setState({ inputLists: values });
  };

  onChangeBrand = (e) => {
    this.setState({ selectedBrandId: e.target.value });
    var index = this.state.brands.findIndex(
      (brand) => brand._id === e.target.value
    );
    this.setState({
      selectedBrandName: this.state.brands[index].name,
      brandShortCode: this.state.brands[index].brandShortCode,
    });
  };

  onChangeLocation = (event) => {
    this.setState({ selectedLocations: event.target.value });
  };

  onChangePrice = (e) => {
    if (e.target.value < 0) {
      toast.error("price cannot be less than 0");
      this.setState({ price: (e.target.value = 0) });
    } else {
      this.setState({ price: parseInt(e.target.value) });
    }
  };
  onChangeCountInStock = (e) => {
    if (e.target.value < 0) {
      toast.error("stock cannot be less than 0");
      this.setState({ countInStock: (e.target.value = 0) });
    } else {
      this.setState({ countInStock: parseInt(e.target.value) });
    }
  };
  onChangeMinimumQuantity = (e) => {
    this.setState({ minimumQuantity: parseInt(e.target.value) });
  };
  onBlurMin = (e) => {
    if (e.target.value > this.state.maximumQuantity) {
      toast.error("min quantity sould be less than max quantity");
    } else if (e.target.value <= 0) {
      toast.error("min quantity should be greater than 0");
    }
  };
  onBlurMax = (e) => {
    if (e.target.value < this.state.minimumQuantity) {
      toast.error("max quantity should be greater than min quantity");
    }
  };
  onChangeMaxQuantity = (e) => {
    this.setState({ maximumQuantity: parseInt(e.target.value) });
  };

  onChangeProductName = (e) => {
    this.setState({ productName: e.target.value });
  };

  onChangeCategory = (e) => {
    this.setState({ selectedCategory: e.target.value });
    var index = this.state.category.findIndex(
      (brand) => brand._id === e.target.value
    );
    this.setState({
      categoryShortCode: this.state.category[index].categoryShortCode,
    });
    this.getSubcategory(e.target.value);
  };

  onChangeSubCategory = (e) => {
    this.setState({ selectedSubcategory: e.target.value });
    var index = this.state.subCategory.findIndex(
      (subcat) => subcat._id === e.target.value
    );
    this.setState({
      subCategoryShortCode: this.state.subCategory[index].subCategoryShortCode,
    });
  };

  onBlurMaxTransportVolume = (e) => {
    if (e.target.value <= 0) {
      toast.error("Max transport volume cannot be less than 1");
    }
  };

  onChangeSellEverywhere = (e) => {
    this.setState({ sellEverywhere: !this.state.sellEverywhere });
    if (e.target.value === "false") {
      this.setState({ selectedLocations: ["All"] });
    } else if (e.target.value === "true") {
      this.setState({ selectedLocations: [] });
    }
  };
  onChangeDeliveryAvailable = (e) => {
    this.setState({ deliveryAvailable: e.target.value });
    if (e.target.value === "Yes") {
      this.setState({ isDeliveryAvailable: true });
    } else {
      this.setState({
        // sellEverywhere: false,
        maxTransportationVolume: 0,
        isDeliveryAvailable: false,
        transportationCharges: "No",
        isTransportationCharges: false,
        deliveryInputFields: [
          {
            deliveryLocation: "",
            deliveryTime: "",
            deliveryTimeUnit: "",
          },
        ],
        inputLists: [
          {
            transportLocatiion: "",
            transportCharges: "",
          },
        ],
      });
    }
  };
  // onChangeShipmentCharges = (e) => {
  //   if (e.target.value < 0) {
  //     toast.error("Shipment cost cannot be less than 0");
  //     this.setState({ shipmentCharges: (e.target.value = 0) });
  //   } else {
  //     this.setState({ shipmentCharges: e.target.value });
  //   }
  // };
  onChangeProductFeatureAd = (e) => {
    this.setState({ productFeatureAd: e.target.value });
    if (e.target.value === "Yes") {
      this.setState({ isProductFeatureAd: true });
    } else {
      this.setState({ isProductFeatureAd: false });
    }
  };
  onChangeSelectValue = (e) => {
    this.setState({ chooseMultiple: e.target.value });
  };

  onChangeMultipleAllow = (e) => {
    if (e.target.value === "true") {
      this.setState({ isMultipleAllow: true });
    } else {
      this.setState({ isMultipleAllow: false });
    }
  };
  onChangeTransportationCharges = (e) => {
    this.setState({ transportationCharges: e.target.value });
    if (e.target.value === "Yes") {
      this.setState({ isTransportationCharges: true });
    } else {
      this.setState({
        isTransportationCharges: false,
        maxTransportationVolume: 0,
        // shipmentCharges: 0,
        inputLists: [
          {
            transportLocatiion: "",
            transportCharges: "",
          },
        ],
      });
    }
  };

  onChangeFiles = async (e) => {
    const files = e.target.files;
    var images = [];
    this.setState({ isUpload: true });

    for (var i = 0; i < Object.keys(files).length; i++) {
      const data = new FormData();
      data.append("file", files[i]);
      data.append("upload_preset", "hammad");
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dz5fltj9r/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const imagesUrl = await res.json();
      images.push(imagesUrl.secure_url);
    }
    images &&
      images?.length > 0 &&
      images?.map((im) => {
        this.state.files?.push(im);
      });
    this.setState({ files: [...this.state.files], isUpload: false });
  };

  onChangeProductWeight = (e) => {
    if (e.target.value < 0) {
      toast.error("weight cannot be less than 0");
      this.setState({ productWeight: (e.target.value = 0) });
    } else {
      this.setState({ productWeight: e.target.value });
    }
  };

  onChangeProductDepth = (e) => {
    if (e.target.value < 0) {
      toast.error("depth cannot be less than 0");
      this.setState({ productDepth: (e.target.value = 0) });
    } else {
      this.setState({ productDepth: e.target.value });
    }
  };

  onChangeProductWidth = (e) => {
    if (e.target.value < 0) {
      toast.error("width cannot be less than 0");
      this.setState({ productWidth: (e.target.value = 0) });
    } else {
      this.setState({ productWidth: e.target.value });
    }
  };

  onChangeProductHeight = (e) => {
    if (e.target.value < 0) {
      toast.error("height cannot be less than 0");
      this.setState({ productHeight: (e.target.value = 0) });
    } else {
      this.setState({ productHeight: e.target.value });
    }
  };

  onChangeColorFamily = (e) => {
    this.setState({ colorFamily: e.target.value });
    var index = this.state.colors.findIndex(
      (color) => color._id === e.target.value
    );
    this.setState({ colorShortCode: this.state.colors[index].colorShortCode });
  };

  handleDeleteProductTags(deletedChip) {
    this.setState({
      productTags: this.state.productTags.filter((c) => c !== deletedChip),
    });
  }

  removeImages = (imgSrc) => {
    let files = this.state.files;
    const index = files.indexOf(imgSrc);
    if (index > -1) {
      files.splice(index, 1);
    }
    this.setState({ files });
  };

  handleAddProduct = (event) => {
    const formData = new FormData();
    let tagData = {
      name: this.state.productTags,
    };
    let productData = {
      name: this.state.productName,
      image: JSON.stringify(this.state.files),
      brandId: this.state.selectedBrandId,
      brand: this.state.selectedBrandName,
      price: this.state.price,
      priceUnit: this.state.priceUnit,
      countInStock: this.state.countInStock,

      maxTransportationVolume: this.state.maxTransportationVolume,
      productTags: JSON.stringify(this.state.productTags),
      minimumQuantity: this.state.minimumQuantity,
      description: this.state.description,

      categoryId: this.state.selectedCategory,
      active: this.state.active,
      type: this.state.type,
      locationByCity: JSON.stringify(this.state.selectedLocations),
      sellerName: localStorage.getItem("sellerName"),
      colorId: this.state.colorFamily,
      chooseMultiple: this.state.chooseMultiple
        ? this.state.chooseMultiple
        : "",

      categoryShortCode: this.state.categoryShortCode,
      subCategoryShortCode: this.state.subCategoryShortCode,
      brandShortCode: this.state.brandShortCode,
      colorShortCode: this.state.colorShortCode,

      maximumQuantity: this.state.maximumQuantity,
      standardCompliance: this.state.standardCompliance,
      delivery: this.state.isDeliveryAvailable,
      shippingCharges: this.state.shipmentCharges,
      transportationCharges: this.state.isTransportationCharges,
      isMultipleAllow: this.state.isMultipleAllow,
      transportationPolicy: this.state.transportationPolicy,
      returnPolicy: this.state.returnPolicy,
      cancellationPolicy: this.state.cancellationPolicy,
      breakingPolicy: this.state.breakagePolicy,
      weightDeviationPolicy: this.state.weightDeviationPolicy,
      productUpdates: this.state.productUpdates,
      updatesFrequency: this.state.updateFrequency,
      productFeature: this.state.isProductFeatureAd,
      adFrequency: this.state.adFrequency,
      productWidth: this.state.productWidth,
      productHeight: this.state.productHeight,
      productDepth: this.state.productDepth,
      productWeight: this.state.productWeight,
      subCategoryId: this.state.selectedSubcategory,
      transportCharges: JSON.stringify(this.state.inputLists),
      deliveryTimeLine: JSON.stringify(this.state.deliveryInputFields),
    };
    for (const [key, value] of Object.entries(productData)) {
      formData.append(key, value);
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true });
    if (this.state.type === "Product") {
      if (
        this.state.isDeliveryAvailable &&
        this.state.selectedLocations.length <= 0
      ) {
        toast.error("Please choose a location for deilvery");
        return;
      } else if (
        this.state.isDeliveryAvailable &&
        this.state.isTransportationCharges &&
        this.state.maxTransportationVolume <= 0
      ) {
        toast.error("Max transport volume cannot be less than 1");
        return;
      } else if (this.state.isMultipleAllow && !this.state.chooseMultiple) {
        toast.error("Choose multiple can't be empty if multiples are allowed");
        return;
      } else if (this.state.minimumQuantity > this.state.maximumQuantity) {
        toast.error("min quantity sould be less than max quantity");
        return;
      } else if (this.state.maximumQuantity <= 0) {
        toast.error("min quantity should be greater than 0");
        return;
      }
    }

    if (
      !this.state.productName ||
      !this.state.price ||
      !this.state.priceUnit ||
      // this.state.countInStock === "" ||
      !this.state.productTags ||
      !this.state.description ||
      !this.state.selectedCategory ||
      !this.state.selectedSubcategory
      //  ||
      // this.state.selectedBrandId === "" ||
      // this.state.productWeight === ""
    ) {
      this.setState({ err: true });
      toast.error("Please fill all required fields to proceed");
    } else {
      this.setState({ err: false });
      this.setState({ isLoading: true });
      if (this.state.isNew) {
        this.addProduct(formData);
      } else {
        this.updateProduct(formData, this.state.productId);
      }
      addTags(tagData);
    }
  };

  addProduct = (productData) => {
    axiosClient()
      .post("/createProduct", productData)
      .then((response) => {
        toast.success(response?.data?.message);
        this.setState({
          isLoading: false,
          categroryId: "",
          subCategoryId: "",
        });
        setTimeout(
          () => this.props.history.push(`/products/view-products`),
          2000
        );
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
        this.setState({ isLoading: false });
      });
  };

  updateProduct = (productData, id) => {
    axiosClient()
      .put(`/updateProduct/${id}`, productData)
      .then((response) => {
        toast.success(response?.data?.message);
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { type } = this.state;
    return (
      <>
        <SideBar />
        <ToastContainer autoClose={2000} />
        <div className="addproduct_heading">Basic Information</div>
        <div className="addproduct_div">
          <Grid container>
            <Grid item lg={12}>
              <Form
                noValidate
                validated={this.state.validated}
                className="addproduct_form"
              >
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    Product/Service
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    {" "}
                    <Form.Control
                      as="select"
                      value={type}
                      onChange={(e) => this.setState({ type: e.target.value })}
                    >
                      <option value="Product">Product</option>
                      <option value="Service">Service</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    {type === "Product" ? "Product Name" : "Service Name"}{" "}
                    <span className="required">*</span>
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    <Form.Control
                      type="text"
                      value={this.state.productName}
                      onChange={this.onChangeProductName}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {type === "Product"
                        ? "Product Name is required"
                        : "Service Name is required"}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    Category
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    <Form.Control
                      as="select"
                      value={this.state.selectedCategory}
                      onChange={this.onChangeCategory}
                    >
                      <option value="" disabled />
                      {this.state.category?.length > 0 &&
                        this.state.category?.map((cat) => (
                          <option key={cat._id} value={cat._id}>
                            {cat.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    Sub Category <span className="required">*</span>
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    <Form.Control
                      as="select"
                      value={this.state.selectedSubcategory}
                      onChange={this.onChangeSubCategory}
                      required
                    >
                      <option disabled selected />
                      {this.state.subCategory?.length > 0 &&
                        this.state.subCategory?.map((subcat) => (
                          <option key={subcat._id} value={subcat._id}>
                            {subcat.subCategoryName}
                          </option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Subcategory is required
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {type === "Product" ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm={12} md={12} lg={2}>
                      Brand <span className="required">*</span>
                    </Form.Label>
                    <Col sm={12} md={12} lg={9}>
                      <Form.Control
                        as="select"
                        value={this.state.selectedBrandId}
                        onChange={this.onChangeBrand}
                        required
                      >
                        {this.state.brands?.length > 0 &&
                          this.state.brands?.map((brand) => (
                            <option key={brand._id} value={brand._id}>
                              {brand.name}
                            </option>
                          ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Brand is required
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                ) : (
                  ""
                )}
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    Price <span className="required">*</span>
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    <Form.Control
                      type="number"
                      value={this.state.price}
                      onChange={this.onChangePrice}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Price is required
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    Price Unit
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    {" "}
                    <Form.Control
                      as="select"
                      value={this.state.priceUnit}
                      onChange={(e) =>
                        this.setState({ priceUnit: e.target.value })
                      }
                    >
                      <option value="" disabled />
                      {type === "Product" ? (
                        <>
                          <option value="/kg">per kg</option>
                          <option value="/unit">per unit</option>
                        </>
                      ) : (
                        <>
                          <option value="per hour">per hour</option>
                          <option value="per sq ft">per sq ft</option>
                          <option value="per day">per day</option>
                        </>
                      )}
                    </Form.Control>
                  </Col>
                </Form.Group>
                {type === "Product" ? (
                  <>
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Count in stock <span className="required">*</span>
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <Form.Control
                          type="number"
                          value={this.state.countInStock}
                          onChange={this.onChangeCountInStock}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Stock is required
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Standard Compliance
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <Form.Control
                          type="text"
                          value={this.state.standardCompliance}
                          onChange={(e) =>
                            this.setState({
                              standardCompliance: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}
              </Form>
            </Grid>
          </Grid>
        </div>

        <div className="addproduct_heading">Detailed Description</div>
        <div className="addproduct_div">
          <Grid container>
            <Grid item lg={12}>
              <Form
                className="addproduct_form"
                noValidate
                validated={this.state.validated}
              >
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    Detailed Description <span className="required">*</span>
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    {/* <Form.Control
                      as="textarea"
                      rows={3}
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Description is required
                    </Form.Control.Feedback> */}

                    <ReactQuill
                      className="quill"
                      modules={this.modules}
                      value={this.state.description}
                      onChange={(description) => this.setState({ description })}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    {type === "Product" ? "Product Tags" : "Service Tags"}{" "}
                    <span className="required">*</span>
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    <ChipInput
                      disableUnderline={true}
                      value={this.state.productTags}
                      onAdd={(tags) =>
                        this.setState({
                          productTags: [...this.state.productTags, tags],
                        })
                      }
                      onDelete={(tag) => this.handleDeleteProductTags(tag)}
                      style={{ padding: "6px 5px 0px 5px" }}
                    />
                    <Form.Text className="text-muted">
                      Press "Enter" to add a tag
                    </Form.Text>
                  </Col>
                </Form.Group>
              </Form>
            </Grid>
          </Grid>
        </div>

        {type === "Product" ? (
          <>
            <div className="addproduct_heading">Quantity</div>
            <div className="addproduct_div">
              <Grid container>
                <Grid item lg={12}>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    className="addproduct_form"
                  >
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Minimum Quantity
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <Form.Control
                          type="number"
                          value={this.state.minimumQuantity}
                          onChange={this.onChangeMinimumQuantity}
                          onBlur={this.onBlurMin}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Maximum Quantity
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <Form.Control
                          type="number"
                          name="maximumQuantity"
                          value={this.state.maximumQuantity}
                          onChange={this.onChangeMaxQuantity}
                          onBlur={this.onBlurMax}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Allow Multiples
                      </Form.Label>
                      <Col sm={12} md={12} lg={9} style={{ marginTop: "0.5%" }}>
                        <Form.Check
                          inline
                          value={false}
                          label="No"
                          type="radio"
                          id="false"
                          name="isMultipleAllow"
                          checked={this.state.isMultipleAllow === false}
                          onChange={this.onChangeMultipleAllow}
                        />
                        <Form.Check
                          inline
                          value={true}
                          label="Yes"
                          type="radio"
                          id="true"
                          name="isMultipleAllow"
                          checked={this.state.isMultipleAllow === true}
                          onChange={this.onChangeMultipleAllow}
                        />
                      </Col>
                    </Form.Group>
                    {this.state.isMultipleAllow === true ? (
                      <Form.Group as={Row}>
                        <Form.Label column sm={12} md={12} lg={2}>
                          Choose Multiple <span className="required">*</span>
                        </Form.Label>
                        <Col sm={12} md={12} lg={9}>
                          <Form.Control
                            input="number"
                            as="select"
                            name="chooseMultiple"
                            value={this.state.chooseMultiple}
                            onChange={this.onChangeSelectValue}
                          >
                            <option value="" disabled />
                            {this.state.chooseMultipleValues?.length > 0 &&
                              this.state.chooseMultipleValues?.map((cmv) => (
                                <option key={cmv} value={cmv}>
                                  {cmv}
                                </option>
                              ))}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </Form>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="addproduct_heading">
          {type === "Product" ? "Colors & Images" : "Images"}
        </div>
        <div className="addproduct_div">
          <Grid container>
            <Grid item lg={12}>
              <Form
                noValidate
                validated={this.state.validated}
                className="addproduct_form"
              >
                {type === "Product" ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm={12} md={12} lg={2}>
                      Color Family
                    </Form.Label>
                    <Col sm={12} md={12} lg={9}>
                      {" "}
                      <Form.Control
                        as="select"
                        value={this.state.colorFamily}
                        onChange={this.onChangeColorFamily}
                      >
                        <option value="" disabled />
                        {this.state.colors?.length > 0 &&
                          this.state.colors?.map((color) => (
                            <option key={color._id} value={color._id}>
                              {color.name}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                ) : (
                  ""
                )}

                {this.state.files?.length > 0 ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm={12} md={12} lg={2} />
                    <Col sm={12} md={12} lg={9} className="d-flex">
                      {this.state.files?.map((img) => (
                        <div className="position-relative uploaded-img-div">
                          <span className="position-absolute img-remove-btn">
                            <CloseIcon
                              style={{ fontSize: "13px" }}
                              onClick={() => this.removeImages(img)}
                            />
                          </span>
                          <img
                            src={img}
                            alt={"product-img"}
                            className="uploaded-img"
                          />
                        </div>
                      ))}
                    </Col>
                  </Form.Group>
                ) : (
                  ""
                )}

                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    Upload Images
                  </Form.Label>
                  <Col sm={12} md={12} lg={9}>
                    <Form.Label
                      for="exampleCustomFileBrowser"
                      className="uploadButton form-control"
                    >
                      {this.state.isUpload ? (
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <span className="mr-3">Uploading Image...</span>
                          <CircularProgress color="primary" size={20} />
                        </div>
                      ) : (
                        "Click here to upload image"
                      )}
                      <Form.Control
                        type="file"
                        name="file"
                        id="exampleCustomFileBrowser"
                        label="Upload Images"
                        // defaultValue={this.state.files}
                        onChange={this.onChangeFiles}
                        multiple
                        hidden
                        disabled={this.state.isUpload ? true : false}
                      />
                    </Form.Label>
                  </Col>
                </Form.Group>
              </Form>
            </Grid>
          </Grid>
        </div>

        <div className="addproduct_heading">
          <div style={{ display: "flex" }}>
            Sell All Over The Country{" "}
            <Form.Check
              checked={this.state.sellEverywhere === true}
              size="lg"
              style={{
                marginLeft: "1.5rem",
                cursor: "pointer",
                marginTop: "2.5px",
              }}
              value={this.state.sellEverywhere}
              onChange={this.onChangeSellEverywhere}
              type="checkbox"
            />
          </div>
        </div>
        {!this.state.sellEverywhere ? (
          <div className="addproduct_div">
            <Grid container>
              <Grid item lg={12}>
                <Form className="addproduct_form">
                  <Form.Group as={Row}>
                    <Form.Label column sm={12} md={12} lg={2}>
                      Location
                    </Form.Label>
                    <Col sm={12} md={12} lg={9}>
                      <FormControl>
                        <Select
                          labelId="demo-mutiple-name-label"
                          id="demo-mutiple-name"
                          multiple
                          value={this.state.selectedLocations}
                          onChange={this.onChangeLocation}
                          input={<Input />}
                          disableUnderline={true}
                          className="muiselect"
                        >
                          {this.state.allCities?.length > 0 &&
                            this.state.allCities?.map((name, index) => (
                              <MenuItem key={name._id} value={name.cityName}>
                                {name.cityName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Form.Group>
                </Form>
              </Grid>
            </Grid>
          </div>
        ) : (
          ""
        )}

        {type === "Product" ? (
          <>
            <div className="addproduct_heading">Delivery & Transportation</div>
            <div className="addproduct_div">
              <Grid container>
                <Grid item lg={12}>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    className="addproduct_form"
                  >
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Delivery Available
                      </Form.Label>
                      <Col sm={12} md={12} lg={9} style={{ marginTop: "0.5%" }}>
                        <Form.Check
                          inline
                          label="Yes"
                          type="radio"
                          value="Yes"
                          checked={this.state.deliveryAvailable === "Yes"}
                          onChange={this.onChangeDeliveryAvailable}
                          id="delivery-yes"
                          name="isDeliverable"
                        />
                        <Form.Check
                          inline
                          label="No"
                          type="radio"
                          value="No"
                          checked={this.state.deliveryAvailable === "No"}
                          onChange={this.onChangeDeliveryAvailable}
                          id="delivery-no"
                          name="isDeliverable"
                        />
                      </Col>
                    </Form.Group>
                    {this.state.deliveryAvailable === "Yes"
                      ? this.state.deliveryInputFields?.length > 0 &&
                        this.state.deliveryInputFields?.map(
                          (deliveryInputField, index) => (
                            <div key={index}>
                              <Form.Group as={Row}>
                                <Form.Label column sm={2} md={2} lg={2}>
                                  Location
                                </Form.Label>
                                <Col sm={4} md={4} lg={4}>
                                  <FormControl>
                                    <Select
                                      labelId="demo-mutiple-name-label"
                                      id="demo-mutiple-name"
                                      name="deliveryLocation"
                                      value={
                                        deliveryInputField.deliveryLocation
                                      }
                                      onChange={(event) =>
                                        this.handleDeliveryInput(index, event)
                                      }
                                      input={<Input />}
                                      disableUnderline={true}
                                      className="muiselect"
                                    >
                                      {this.state.sellEverywhere
                                        ? this.state.allCities?.length > 0 &&
                                          this.state.allCities?.map((name) => (
                                            <MenuItem
                                              key={name._id}
                                              value={name.cityName}
                                            >
                                              {name.cityName}
                                            </MenuItem>
                                          ))
                                        : this.state.selectedLocations?.length >
                                            0 &&
                                          this.state.selectedLocations?.map(
                                            (name, index) => (
                                              <MenuItem
                                                key={index}
                                                value={name}
                                              >
                                                {name}
                                              </MenuItem>
                                            )
                                          )}
                                    </Select>
                                  </FormControl>
                                </Col>
                                <Form.Label column sm={2} md={2} lg={2}>
                                  Time Unit
                                </Form.Label>
                                <Col sm={4} md={4} lg={4}>
                                  <FormControl>
                                    <Select
                                      labelId="demo-mutiple-name-label"
                                      id="demo-mutiple-name"
                                      name="deliveryTimeUnit"
                                      value={
                                        deliveryInputField.deliveryTimeUnit
                                      }
                                      onChange={(event) =>
                                        this.handleDeliveryInput(index, event)
                                      }
                                      input={<Input />}
                                      disableUnderline={true}
                                      className="muiselect"
                                    >
                                      {this.state.timeline?.length > 0 &&
                                        this.state.timeline?.map((name) => (
                                          <MenuItem key={name} value={name}>
                                            {name}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </Col>
                                <Form.Label
                                  className="mt-3"
                                  column
                                  sm={2}
                                  md={2}
                                  lg={2}
                                >
                                  Time
                                </Form.Label>
                                <Col className="mt-3" sm={4} md={4} lg={4}>
                                  <Form.Control
                                    type="text"
                                    name="deliveryTime"
                                    value={deliveryInputField.deliveryTime}
                                    onChange={(event) =>
                                      this.handleDeliveryInput(index, event)
                                    }
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" sm={1} md={1} lg={1}>
                                  <div style={{ display: "flex " }}>
                                    <Button
                                      disabled={
                                        this.state.deliveryInputFields
                                          .length === 1
                                      }
                                      className="mr-2"
                                      onClick={() =>
                                        this.handleRemoveDeliveryField(index)
                                      }
                                    >
                                      <RemoveIcon />
                                    </Button>

                                    <Button
                                      onClick={() =>
                                        this.handleAddDeliveryField()
                                      }
                                    >
                                      <AddBoxIcon />
                                    </Button>
                                  </div>
                                </Col>
                              </Form.Group>
                            </div>
                          )
                        )
                      : ""}
                    {this.state.deliveryAvailable === "Yes" ? (
                      <Form.Group as={Row}>
                        <Form.Label column sm={12} md={12} lg={2}>
                          Transportation Charges
                        </Form.Label>
                        <Col
                          sm={12}
                          md={12}
                          lg={9}
                          style={{ marginTop: "0.5%" }}
                        >
                          <Form.Check
                            inline
                            value="No"
                            label="Free"
                            type="radio"
                            id="charges-no"
                            name="isCharge"
                            checked={this.state.transportationCharges === "No"}
                            onChange={this.onChangeTransportationCharges}
                          />
                          <Form.Check
                            inline
                            value="Yes"
                            label="Charges"
                            type="radio"
                            id="charges-yes"
                            name="isCharge"
                            checked={this.state.transportationCharges === "Yes"}
                            onChange={this.onChangeTransportationCharges}
                          />
                        </Col>
                      </Form.Group>
                    ) : (
                      ""
                    )}
                    {this.state.transportationCharges === "Yes" &&
                    this.state.deliveryAvailable === "Yes" ? (
                      <>
                        {" "}
                        <Form.Group as={Row}>
                          <Form.Label column sm={12} md={12} lg={2}>
                            Max Transport Volume{" "}
                            <span className="required">*</span>
                          </Form.Label>
                          <Col sm={12} md={12} lg={9}>
                            <Form.Control
                              type="number"
                              name="maxTransportationVolume"
                              id="maxTransportationVolume"
                              value={this.state.maxTransportationVolume}
                              onBlur={this.onBlurMaxTransportVolume}
                              onChange={(e) =>
                                this.setState({
                                  maxTransportationVolume: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Max Transport Volume is required
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>
                        {this.state.inputLists?.length > 0 &&
                          this.state.inputLists?.map((inputList, index) => (
                            <div key={index}>
                              <Form.Group as={Row}>
                                <Form.Label column sm={12} md={12} lg={2}>
                                  Location
                                </Form.Label>
                                <Col sm={3} md={3} lg={3}>
                                  <FormControl>
                                    <Select
                                      labelId="demo-mutiple-name-label"
                                      id="demo-mutiple-name"
                                      name="transportLocatiion"
                                      value={inputList.transportLocatiion}
                                      onChange={(event) =>
                                        this.handleChangeInput(index, event)
                                      }
                                      input={<Input />}
                                      disableUnderline={true}
                                      className="muiselect"
                                    >
                                      {this.state.sellEverywhere
                                        ? this.state.allCities?.length > 0 &&
                                          this.state.allCities?.map((name) => (
                                            <MenuItem
                                              key={name._id}
                                              value={name.cityName}
                                            >
                                              {name.cityName}
                                            </MenuItem>
                                          ))
                                        : this.state.selectedLocations?.length >
                                            0 &&
                                          this.state.selectedLocations?.map(
                                            (name) => (
                                              <MenuItem key={name} value={name}>
                                                {name}
                                              </MenuItem>
                                            )
                                          )}
                                    </Select>
                                  </FormControl>
                                </Col>
                                <Form.Label column sm={3} md={3} lg={2}>
                                  Shipment charges
                                </Form.Label>
                                <Col sm={3} md={3} lg={2}>
                                  <Form.Control
                                    type="number"
                                    name="transportCharges"
                                    value={inputList.transportCharges}
                                    onChange={(event) =>
                                      this.handleChangeInput(index, event)
                                    }
                                    required
                                  />
                                </Col>

                                <Col sm={1} md={1} lg={1}>
                                  <div style={{ display: "flex " }}>
                                    <Button
                                      disabled={
                                        this.state.inputLists.length === 1
                                      }
                                      className="mr-2"
                                      onClick={() =>
                                        this.handleRemoveField(index)
                                      }
                                    >
                                      <RemoveIcon />
                                    </Button>

                                    <Button
                                      onClick={() => this.handleAddField()}
                                    >
                                      <AddBoxIcon />
                                    </Button>
                                  </div>
                                </Col>
                              </Form.Group>
                            </div>
                          ))}
                      </>
                    ) : (
                      ""
                    )}
                  </Form>
                </Grid>
              </Grid>
            </div>
            <div className="addproduct_heading">Company Policies </div>
            <div className="addproduct_div">
              <Grid container>
                <Grid item lg={12}>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    className="addproduct_form"
                  >
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Transportation Policy
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <ReactQuill
                          className="quill"
                          modules={this.modules}
                          value={this.state.transportationPolicy}
                          onChange={(transportationPolicy) =>
                            this.setState({ transportationPolicy })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Return Policy
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <ReactQuill
                          className="quill"
                          modules={this.modules}
                          value={this.state.returnPolicy}
                          onChange={(returnPolicy) =>
                            this.setState({ returnPolicy })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Cancellation Policy
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <ReactQuill
                          className="quill"
                          modules={this.modules}
                          value={this.state.cancellationPolicy}
                          onChange={(cancellationPolicy) =>
                            this.setState({ cancellationPolicy })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Breakage Policy
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <ReactQuill
                          className="quill"
                          modules={this.modules}
                          value={this.state.breakagePolicy}
                          onChange={(breakagePolicy) =>
                            this.setState({ breakagePolicy })
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Weight Deviation Policy
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        <ReactQuill
                          className="quill"
                          modules={this.modules}
                          value={this.state.weightDeviationPolicy}
                          onChange={(weightDeviationPolicy) =>
                            this.setState({ weightDeviationPolicy })
                          }
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </Grid>
              </Grid>
            </div>
            <div className="addproduct_heading">Weight & Dimensions</div>
            <div className="addproduct_div">
              <Grid container>
                <Grid item lg={12}>
                  <Form
                    className="addproduct_form"
                    noValidate
                    validated={this.state.validated}
                  >
                    <Form.Group as={Row} className="dimensions">
                      <Form.Label column sm={12} md={12} lg={2}>
                        Product Weight <span className="required">*</span>
                      </Form.Label>
                      <Col sm={12} md={12} lg={4}>
                        <Form.Control
                          type="number"
                          placeholder="(kgs)"
                          value={this.state.productWeight}
                          onChange={this.onChangeProductWeight}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Product weight is required
                        </Form.Control.Feedback>
                      </Col>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Product Depth
                      </Form.Label>
                      <Col sm={12} md={12} lg={4}>
                        <Form.Control
                          type="number"
                          placeholder="(inches)"
                          value={this.state.productDepth}
                          onChange={this.onChangeProductDepth}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="dimensions">
                      <Form.Label column sm={12} md={12} lg={2}>
                        Product Width
                      </Form.Label>
                      <Col sm={12} md={12} lg={4}>
                        <Form.Control
                          type="number"
                          placeholder="(inches)"
                          value={this.state.productWidth}
                          onChange={this.onChangeProductWidth}
                        />
                      </Col>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Product Height
                      </Form.Label>
                      <Col sm={12} md={12} lg={4}>
                        <Form.Control
                          type="number"
                          placeholder="(inches)"
                          value={this.state.productHeight}
                          onChange={this.onChangeProductHeight}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                </Grid>
              </Grid>
            </div>
            <div className="addproduct_heading">Product Updates</div>
            <div className="addproduct_div">
              <Grid container>
                <Grid item lg={12}>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    className="addproduct_form"
                  >
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Product Updates
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        {" "}
                        <Form.Control
                          as="select"
                          value={this.state.productUpdates}
                          onChange={(e) =>
                            this.setState({ productUpdates: e.target.value })
                          }
                        >
                          <option key="Manual" value="Manual">
                            Manual
                          </option>
                          <option key="Portal Access" value="Portal Access">
                            Portal Access
                          </option>
                          <option key="Integration" value="Integration">
                            Integration
                          </option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={12} md={12} lg={2}>
                        Update Frequency
                      </Form.Label>
                      <Col sm={12} md={12} lg={9}>
                        {" "}
                        <Form.Control
                          as="select"
                          value={this.state.updateFrequency}
                          onChange={(e) =>
                            this.setState({ updateFrequency: e.target.value })
                          }
                        >
                          <option key="Daily" value="Daily">
                            Daily
                          </option>
                          <option key="Weekly" value="Weekly">
                            Weekly
                          </option>
                          <option key="Monthly" value="Monthly">
                            Monthly
                          </option>
                          <option key="Quarterly" value="Quarterly">
                            Quarterly
                          </option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </Form>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="addproduct_heading">
          {type === "Product" ? "Product Ads" : "Service Ads"}
        </div>
        <div className="addproduct_div">
          <Grid container>
            <Grid item lg={12}>
              <Form
                noValidate
                validated={this.state.validated}
                className="addproduct_form"
              >
                <Form.Group as={Row}>
                  <Form.Label column sm={12} md={12} lg={2}>
                    {type === "Product"
                      ? "Product Feature - Ad"
                      : "Service Feature - Ad"}
                  </Form.Label>
                  <Col sm={12} md={12} lg={9} style={{ marginTop: "0.5%" }}>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      id="featuread-yes"
                      name="productFeatureAd"
                      value="Yes"
                      checked={this.state.productFeatureAd === "Yes"}
                      onChange={this.onChangeProductFeatureAd}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      id="featuread-no"
                      name="productFeatureAd"
                      value="No"
                      checked={this.state.productFeatureAd === "No"}
                      onChange={this.onChangeProductFeatureAd}
                    />
                  </Col>
                </Form.Group>
                {this.state.isProductFeatureAd ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm={12} md={12} lg={2}>
                      Ad Frequency
                    </Form.Label>
                    <Col sm={12} md={12} lg={9}>
                      {" "}
                      <Form.Control
                        as="select"
                        value={this.state.adFrequency}
                        onChange={(e) =>
                          this.setState({ adFrequency: e.target.value })
                        }
                      >
                        <option key="Week" value="Week">
                          Week
                        </option>
                        <option key="Month" value="Month">
                          Month
                        </option>
                        <option key="6 Months" value="6 Months">
                          6 Months
                        </option>
                        <option key="1 Year" value="1 Year">
                          1 Year
                        </option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                ) : (
                  ""
                )}
              </Form>
            </Grid>
          </Grid>
        </div>

        <div className="addproduct_button">
          <Button
            className="d-flex align-items-center"
            onClick={this.handleAddProduct}
            disabled={this.state.isLoading ? true : false}
          >
            {this.state.isLoading ? (
              <>
                <span className="mr-3">Please wait</span>
                <CircularProgress color="inherit" size={20} />
              </>
            ) : this.state.isNew ? (
              type === "Product" ? (
                "Add Product"
              ) : (
                "Add Service"
              )
            ) : type === "Product" ? (
              "Update Product"
            ) : (
              "Update Service"
            )}
          </Button>
        </div>
      </>
    );
  }
}
export default AddProduct;
