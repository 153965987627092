import React, { Component } from "react";
import "./AddressBookForm.css";
import Grid from "@material-ui/core/Grid";
import { Form, Row, Col } from "react-bootstrap";

class AddressBookForm extends Component {
  render() {
    return (
      <>
        <Grid container>
          <Grid item lg={12}>
            <Form className="address_form">
              <h3>{this.props.addressHeading}</h3>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Address
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    value={this.props.address}
                    onChange={this.props.onChangeAddress}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" htmlFor="inlineFormCustomSelect">
                  Country/Region
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    //  custom
                  >
                    <option value="Pakistan">Pakistan</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" htmlFor="inlineFormCustomSelect">
                  State
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    // custom
                    defaultValue={this.props.states}
                    onChange={this.props.onChangeState}
                  >
                    {this.props.states.map((stat) => (
                      <option key={stat.value} value={stat.value}>
                        {stat.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" htmlFor="inlineFormCustomSelect">
                  Area
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    // custom
                    defaultValue={this.props.areas}
                    onChange={this.props.onChangeArea}
                  >
                    {this.props.areas.map((area) => (
                      <option key={area.value} value={area.value}>
                        {area.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2" htmlFor="inlineFormCustomSelect">
                  Location
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelect"
                    // custom
                    defaultValue={this.props.locations}
                    onChange={this.props.onChangeLocation}
                  >
                    {this.props.locations.map((location) => (
                      <option key={location.value} value={location.value}>
                        {location.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Form>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default AddressBookForm;
