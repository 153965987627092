import React, { Component } from "react";
import { RiLogoutBoxRLine } from "react-icons/ri";

import iownLogo from "../../assets/iownLogo.png";
import classes from "./SimpleHeader.module.css";

class SimpleHeader extends Component {
  logout = () => {
    localStorage.clear();
    window.location = "/";
  };
  render() {
    return (
      <>
        {localStorage.getItem("sellerId") ? (
          <div className={classes.logout} onClick={this.logout}>
            <label style={{ cursor: "pointer" }}>Logout</label>{" "}
            <RiLogoutBoxRLine style={{ color: "#4574aa" }} />
          </div>
        ) : (
          ""
        )}
        <div className={classes.header}>
          <img src={iownLogo} alt="iown.pk" className={classes.iownLogo} />
          {/* <h5 className={classes.logo}>Deosai</h5> */}
        </div>
      </>
    );
  }
}
export default SimpleHeader;
