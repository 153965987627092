import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { ToastContainer } from "react-toastify";
import classes from "./BasicInfoForm.module.css";

class BasicInfoForm extends Component {
  render() {
    return (
      <>
        <ToastContainer autoClose={3000} />
        <div className={classes.basicinfo_headings}>
          <p>Step 1 of 2</p>
          <h4>Tell us a little about yourself</h4>
          <span className="text-muted">
            We'll help you get started based on your responses
          </span>
        </div>
        <Form>
          <Form.Group controlId="FormControlSelect1">
            <Form.Label className={classes.form_labels}>
              Choose your account type <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              as="select"
              value={this.props.accountType}
              onChange={this.props.onChangeAccountType}
              className={classes.basicinfo_form_control}
            >
              <option value="Individual">Individual</option>
              <option value="Business">Business</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="FormControlSelect1">
            <Form.Label className={classes.form_labels}>
              Based in <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              as="select"
              value={this.props.shopLocation}
              onChange={this.props.onChangeShopLocation}
              className={classes.basicinfo_form_control}
            >
              <option value="" disabled />
              {this.props.provinces?.map((province) => (
                <option key={province._id} value={province._id}>
                  {province.provinceName}
                </option>
              ))}
            </Form.Control>
            {this.props.shopLocationErr ? (
              <span className={classes.error_display}>
                {this.props.shopLocationErr}
              </span>
            ) : null}
          </Form.Group>
          <Form.Group controlId="FormControlSelect2">
            <Form.Label className={classes.form_labels}>
              City <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              as="select"
              value={this.props.selectedCity}
              onChange={this.props.onChangeCity}
              className={classes.basicinfo_form_control}
            >
              <option value="" disabled />
              {this.props.cities?.map((city) => (
                <option key={city._id} value={city.cityName}>
                  {city.cityName}
                </option>
              ))}
            </Form.Control>
            {this.props.cityErr ? (
              <span className={classes.error_display}>
                {this.props.cityErr}
              </span>
            ) : null}
          </Form.Group>

          <Form.Group controlId="formMobileNumber">
            <Form.Label className={classes.form_labels}>
              Business Address <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="businessAddress"
              className={classes.basicinfo_form_control}
              value={this.props.businessAddress}
              onChange={this.props.onChangeBusinessAddress}
            />
            {this.props.addressErr ? (
              <span className={classes.error_display}>
                {this.props.addressErr}
              </span>
            ) : null}
          </Form.Group>

          <Form.Group controlId="formMobileNumber">
            <Form.Label className={classes.form_labels}>
              Mobile Number <span className="required">*</span>
            </Form.Label>

            <ReactInputMask
              required
              mask={"+\\92 999 9999999"}
              alwaysShowMask={false}
              type="text"
              name="phoneNo"
              className={classes.basicinfo_form_control}
              value={this.props.phoneNo}
              onChange={this.props.onChangePhoneNo}
            >
              {() => (
                <Form.Control
                  name="phoneNo"
                  className={classes.basicinfo_form_control}
                  value={this.props.phoneNo}
                />
              )}
            </ReactInputMask>
            {this.props.phoneErr ? (
              <span className={classes.error_display}>
                {this.props.phoneErr}
              </span>
            ) : null}
          </Form.Group>

          <Form.Group controlId="forPostalCode">
            <Form.Label className={classes.form_labels}>
              Postal Code <span className="required">*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              name="postalCode"
              className={classes.basicinfo_form_control}
              value={this.props.postalCode}
              onChange={this.props.onChangePostalCode}
            />
            {this.props.postalCodeErr ? (
              <span className={classes.error_display}>
                {this.props.postalCodeErr}
              </span>
            ) : null}
          </Form.Group>

          <Form.Group controlId="formBasicCheckbox">
            <div className={classes.checkout_div}>
              <Form.Check
                type="checkbox"
                checked={this.props.isAccepted}
                value={this.props.isAccepted}
                onChange={this.props.onChangeIsAccepted}
              />
              <Form.Label className={classes.form_labels}>
                I've read and agreed to Deosai's{" "}
                <Link to="/terms">Terms & Conditions</Link>
              </Form.Label>
            </div>
            {this.props.isAcceptedErr ? (
              <span
                className={classes.error_display}
                style={{ display: "none" }}
              >
                {this.props.isAcceptedErr}
              </span>
            ) : null}
          </Form.Group>
        </Form>
      </>
    );
  }
}
export default BasicInfoForm;
