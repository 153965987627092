import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import SideBar from "../../components/sidebar/Sidebar";
import OrderProcess from "../../components/verification/orderProcessing";
import axiosClient from "../../helper";

import "../verifyBankAccount/VerifyBankAccount.css";

const OrderProcessInfo = () => {
  const [processOrder, setProcessOrder] = useState({
    orderProcess: ["Email", "Call", "Whatsapp"],
    orderReceiptConfirmation: "Email",
    orderDeliveryConfirmation: "Email",
    email: "",
    phoneNo: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axiosClient()
      .get(`/getUsersById/${localStorage.getItem("sellerId")}`)
      .then((response) => {
        setProcessOrder({
          ...processOrder,
          orderDeliveryConfirmation: response.data.user
            .sellerOrderDeliveryConfirmation
            ? response.data.user.sellerOrderDeliveryConfirmation
            : "Email",
          orderReceiptConfirmation: response.data.user
            .sellerOrderReceiptConfirmation
            ? response.data.user.sellerOrderReceiptConfirmation
            : "Email",
          email: response.data.user.sellerOrderEmail,
          phoneNo: response.data.user.sellerOrderPhone,
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleOrderProcess = (event) => {
    setProcessOrder({
      ...processOrder,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddOrderProcess = () => {
    let orderProcess = {
      orderReceiptConfirmation: processOrder.orderReceiptConfirmation,
      orderDeliveryConfirmation: processOrder.orderDeliveryConfirmation,
      sellerOrderEmail: processOrder.email,
      sellerOrderPhone: processOrder.phoneNo,
    };
    if (!processOrder.email && !processOrder.phoneNo) {
      toast.error("Please provide either phone # or email");
    } else if (
      processOrder.phoneNo &&
      processOrder.phoneNo.replace(/-|_/g, "").length < 15
    ) {
      toast.error("Phone number is invalid");
    } else {
      setIsLoading(true);
      axiosClient()
        .post("/sellerOrderProcessing", orderProcess)
        .then((response) => {
          toast.success("Details updated");
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error(error.response?.data.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <SideBar />
      <div className="m-div">
        <div className="verifybank_heading">Order Processing</div>
        <OrderProcess
          processOrder={processOrder}
          handleAddOrderProcess={handleAddOrderProcess}
          handleOrderProcess={handleOrderProcess}
          isUpdate={true}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
export default OrderProcessInfo;
