import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import classes from "./emailVerify.module.css";
import SimpleHeader from "../../components/simpleHeader/SimpleHeader";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import axiosClient from "../../helper";

class EmailVerify extends Component {
  state = {
    email: "",
    loader: false,
  };

  onChangeEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleForgetEmail = (e) => {
    e.preventDefault();
    const loginDetails = {
      email: this.state.email,
      userRole: "seller",
    };
    if (!this.state.email) {
      toast.error("Please Enter Your Email Address ");
    } else {
      this.setState({ loader: true });
      axiosClient()
        .post(`/forgotPassword`, loginDetails)
        .then((response) => {
          // console.log("forgot password response", response);
          this.setState({ loader: false });

          setTimeout(() => this.props.history.push(`/verify-code`), 2000);
          toast.success(response.data.message);
        })
        .catch((error) => {
          // console.log("Error Message", error);
          this.setState({ loader: false });

          toast.error(error.response.data.message);
        });
    }
  };
  render() {
    return (
      <>
        <SimpleHeader />

        <ToastContainer autoClose={2000} />
        <Container>
          <Row style={{ justifyContent: "center" }}>
            <Col sm={10} md={5}>
              <div className={classes.email_div}>
                <h4 className={classes.logo}>Forgot Password?</h4>

                <Card className={classes.forget_card}>
                  <Card.Title className={classes.forget_heading}>
                    Enter your email so we can send you an email to reset your
                    password
                  </Card.Title>
                  <Form>
                    <Form.Group
                      style={{ position: "relative" }}
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        className={classes.forget_form_control}
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                      />
                      <span className={classes.form_design}>
                        <EmailOutlinedIcon />
                      </span>
                    </Form.Group>
                  </Form>

                  <Button
                    onClick={this.handleForgetEmail}
                    className={classes.forgetbtn}
                    style={{ boxShadow: "none" }}
                    type="submit"
                    disabled={this.state.loader ? true : false}
                  >
                    {this.state.loader === true ? (
                      <span className="d-flex align-items-center justify-content-center">
                        <span className="mr-2">Please wait</span>
                        <CircularProgress
                          className="text-white"
                          color="inherit"
                          size={18}
                        />
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  <span style={{ textAlign: "center", fontSize: "15px" }}>
                    Remember Password? <Link to="/">Login</Link>
                  </span>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default EmailVerify;
