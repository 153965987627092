import React, { Component } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import ReactInputMask from "react-input-mask";

import "./VerifyCard.css";

class ContectDetail extends Component {
  render() {
    return (
      <div className="verify_idcard">
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Form className="verifyid_form">
              <h3 className="verify_subheading">Primary Contact</h3>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Name
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="text"
                    name="primaryName"
                    value={this.props.detailContact.primaryName}
                    onChange={this.props.handleContactData}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Phone #
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  {/* <Form.Control
                    type="number"
                    name="primaryPhoneNo"
                    value={this.props.detailContact.primaryPhoneNo}
                    onChange={this.props.handleContactData}
                  /> */}
                  <ReactInputMask
                    required
                    mask={"+\\92 999 9999999"}
                    alwaysShowMask={false}
                    type="text"
                    name="primaryPhoneNo"
                    value={this.props.detailContact.primaryPhoneNo}
                    onChange={this.props.handleContactData}
                  >
                    {() => (
                      <Form.Control
                        name="primaryPhoneNo"
                        value={this.props.detailContact.primaryPhoneNo}
                      />
                    )}
                  </ReactInputMask>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Address
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="text"
                    name="primaryAddress"
                    value={this.props.detailContact.primaryAddress}
                    onChange={this.props.handleContactData}
                  ></Form.Control>
                </Col>
              </Form.Group>

              <h3 className="verify_subheading">
                Secondary Contact (for escalations)
              </h3>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Name
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="text"
                    name="secondaryName"
                    value={this.props.detailContact.secondaryName}
                    onChange={this.props.handleContactData}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Phone #
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  {/* <Form.Control
                    type="number"
                    name="secondaryPhoneNo"
                    value={this.props.detailContact.secondaryPhoneNo}
                    onChange={this.props.handleContactData}
                  /> */}
                  <ReactInputMask
                    required
                    mask={"+\\92 999 9999999"}
                    alwaysShowMask={false}
                    type="text"
                    name="secondaryPhoneNo"
                    value={this.props.detailContact.secondaryPhoneNo}
                    onChange={this.props.handleContactData}
                  >
                    {() => (
                      <Form.Control
                        name="secondaryPhoneNo"
                        value={this.props.detailContact.secondaryPhoneNo}
                      />
                    )}
                  </ReactInputMask>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={12} md={12} lg={2}>
                  Address
                </Form.Label>
                <Col sm={12} md={12} lg={9}>
                  <Form.Control
                    type="text"
                    name="secondaryAddress"
                    value={this.props.detailContact.secondaryAddress}
                    onChange={this.props.handleContactData}
                  ></Form.Control>
                </Col>
              </Form.Group>
            </Form>
          </Grid>
        </Grid>
        <div className="float-right add-update-btn-m common-buttons">
          <Button
            className="d-flex align-items-center"
            onClick={this.props.handleAddColleagueInfo}
            disabled={this.props.isLoading ? true : false}
          >
            {this.props.isLoading ? (
              <>
                <span className="mr-3">Please wait</span>
                <CircularProgress color="inherit" size={20} />
              </>
            ) : this.props.isUpdate ? (
              "Update Details"
            ) : (
              "Add Details"
            )}
          </Button>
        </div>
        <div
          className={
            this.props.isBackBtn ? "float-right mr-1 common-buttons" : "d-none"
          }
        >
          <Button onClick={this.props.handleBack}>Back</Button>
        </div>
      </div>
    );
  }
}
export default ContectDetail;
