import { axiosClient } from "../../../helper";

export const updateStatus = (id, status) => {
  return axiosClient()
    .post(`/changeStatusOfProduct/${id}`, { status })
    .then((response) => {
      if (response.status === 200) {
        console.log("status updated successfully");
      }
    })
    .catch((error) => {
      console.log("Cannot update status,try again");
      // console.log(error);
    });
};
